<template>
  <div class="container">
    <h2 class="mb-4">Новая выгрузка</h2>
    <ReportsForm :report="newReport"/>
    <div class="d-flex">
      <router-link class="btn btn-outline-secondary me-4" to="/reports">Отмена</router-link>
      <button class="btn btn-primary" @click="createReport">Сохранить</button>
    </div>
  </div>
</template>

<script>
import ReportsForm from '../../components/reports/ReportsForm.vue';
import { apiClient } from '../../custom_functions/api_client';
import { makeToast } from '../../custom_functions/custom_functions';
import { toString } from 'cronstrue';

export default {
  name: 'ReportsNew',

  components: {
    ReportsForm,
  },

  data() {
    return {
      newReport: {
        name: null,
        cron: null  ,
        sql: null,
      },
    };
  },

  methods: {
    async createReport() {
      let correctData = true;
      if (!this.newReport.name) {
        makeToast('Введите имя файла', 'danger');
        correctData = false;
      }
      if (!this.newReport.cron || toString(this.newReport.cron, { throwExceptionOnParseError: false }).includes('error')) {
        makeToast('Введите периодичность выгрузки в cron-формате', 'danger');
        correctData = false;
      }
      if (!this.newReport.sql) {
        makeToast('Введите SQL запрос', 'danger');
        correctData = false;
      }
      if (correctData) {
        await apiClient.post('/v2/google_reports', { report: this.newReport });
        this.$router.push('/reports');
        makeToast('Выгрузка успешно создана', 'success');
      }
    },
  },
};
</script>
