<template>
  <div class="card">
    <div class="card-body px-2 py-3 px-sm-4 py-lg-3">
      <button class="btn btn-block text-start d-flex align-items-baseline p-0" @click="visibleLessons = !visibleLessons">
        <span class="h6 mb-0">
          <font-awesome-icon :icon="visibleLessons ? 'angle-up' : 'angle-down'" class="me-3" />
        </span>
        <div>
          <h4 class="h5 fw-bold">{{ unit.index + 1 }}. {{ unit.name }}</h4>
          <span v-if="unit.awaiting_attempts_count.all" class="text-primary badge rounded-pill border-primary border me-2">
            {{ unit.awaiting_attempts_count.all }} ждут проверки
          </span>
          <span
            v-if="unit.awaiting_attempts_count.rotting"
            :id="`rotting-attempts-unit-${unit.id}`"
            class="text-warning badge rounded-pill border-warning border me-2"
          >
            {{ unit.awaiting_attempts_count.rotting }}
            <font-awesome-icon icon="circle-exclamation" />
            <b-tooltip :target="`rotting-attempts-unit-${unit.id}`" triggers="hover">Меньше суток до окончания срока проверки</b-tooltip>
          </span>
          <span
            v-if="unit.awaiting_attempts_count.expired"
            :id="`expired-attempts-unit-${unit.id}`"
            class="text-danger badge rounded-pill border-danger border me-2"
          >
            {{ unit.awaiting_attempts_count.expired }}
            <font-awesome-icon icon="circle-exclamation" />
            <b-tooltip :target="`expired-attempts-unit-${unit.id}`" triggers="hover">Время для проверки истекло</b-tooltip>
          </span>
        </div>
      </button>
      <b-collapse v-model="visibleLessons" class="pt-3">
        <ol class="list-group">
          <li
            v-for="(lesson, index) in unit.lessons"
            :key="lesson.id"
            class="list-group-item p-0 card border-top"
            :class="index !== unit.lessons.length - 1 && 'mb-2'"
          >
            <div class="card-body p-2 p-sm-3">
              <div class="d-flex align-items-baseline">
                <h5 class="h6 fw-bold me-1">{{ unit.index + 1 }}.{{ lesson.index + 1 }}. {{ lesson.title }}</h5>
                <span v-if="lesson.awaiting_attempts_count.rotting" :id="`rotting-attempts-lesson-${lesson.id}`" class="text-warning me-1">
                  <font-awesome-icon icon="circle-exclamation" />
                  <b-tooltip :target="`rotting-attempts-lesson-${lesson.id}`" triggers="hover">Меньше суток до окончания срока проверки</b-tooltip>
                </span>
                <span v-if="lesson.awaiting_attempts_count.expired" :id="`expired-attempts-lesson-${lesson.id}`" class="text-danger me-1">
                  <font-awesome-icon icon="circle-exclamation" />
                  <b-tooltip :target="`expired-attempts-lesson-${lesson.id}`" triggers="hover">Время для проверки истекло</b-tooltip>
                </span>
              </div>
              <ol class="list-group ps-4">
                <li v-for="step in lesson.steps" :key="step.id" class="list-group-item p-0 border-0 mt-2 mt-sm-3 step">
                  <div class="row">
                    <div class="col-3">
                      <span class="me-2"> {{ unit.index + 1 }}.{{ lesson.index + 1 }}.{{ step.index }} </span>
                      Ждут проверки: <strong>{{ step.awaiting_attempts_count.all }}</strong>
                    </div>
                    <div class="col-9">
                      <u><router-link :to="`/reviewers/${$route.params.reviewerId}/steps/${step.id}`">К решениям</router-link></u>
                    </div>
                  </div>
                </li>
              </ol>
            </div>
          </li>
        </ol>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewerUnit',

  props: {
    unit: Object,
  },

  data() {
    return {
      visibleLessons: false,
    };
  },

  created() {
    this.visibleLessons = +this.$route.query.unit === +this.unit.id;
  },
};
</script>
