import {
  formatDisplayedType,
  getDisplayedTypes,
  getDisplayedTypesExceptContainers,
  getDisplayedTypesOnlyContainers,
} from '@/custom_functions/course_types';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useDisplayedTypeFilter(type) {
  const route = useRoute();
  const router = useRouter();

  let displayedTypes;

  if (type === 'courses') displayedTypes = getDisplayedTypesExceptContainers();
  else if (type === 'containers') displayedTypes = getDisplayedTypesOnlyContainers();
  else displayedTypes = getDisplayedTypes();

  const displayedType = computed({
    get() {
      return route.query.displayed_type;
    },
    set(displayed_type) {
      if (route.query.displayed_type !== displayed_type) {
        router.push({ query: { ...route.query, displayed_type } });
      }
    },
  });

  return {
    displayedTypes,
    displayedType,
    formatDisplayedType,
  };
}
