<template>
  <div class="px-3">
    <div class="row mb-5">
      <h2 class="col-2">Поиск</h2>
      <SearchControl v-model:model-value="params" class="col-7" />
    </div>
    <div v-if="params.query">
      <template v-if="results">
        <div v-if="results.length">
          <table class="table table-sm table-hover table-responsive-lg">
            <thead>
              <tr>
                <th>Курс / Вебинар</th>
                <th>Текст</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(result, index) in results" :key="index">
                <td>
                  <router-link :to="resultLink(result)">
                    {{ resultName(result) }}
                  </router-link>
                </td>
                <td>
                  <template v-if="resultTextFragments(result)">
                    {{ resultTextFragments(result)[0] }}
                    <mark style="background-color: yellow;">{{ resultTextFragments(result)[1] }}</mark>
                    {{ resultTextFragments(result)[2] }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
          <b-pagination
            v-if="totalPages > 1"
            v-model="$route.params.page"
            :total-rows="results.length * totalPages"
            :per-page="results.length"
            class="position-sticky"
            style="bottom: 10px;"
            @change="goToPage($event)"
          />
        </div>
        <span v-else class="text-muted">По запросу "{{ params.query }}" ничего не найдено</span>
      </template>
      <div v-else class="loader text-center">
        <b-spinner/>
      </div>
    </div>
  </div>
</template>

<script>
import SearchControl from '../components/search/SearchControl.vue';
import { apiClient } from '../custom_functions/api_client';

export default {
  name: 'Search',

  components: {
    SearchControl,
  },

  data() {
    return {
      params: {
        query: '',
        searchableTypes: [],
      },
      results: null,
      totalPages: null,
    };
  },

  watch: {
    $route() {
      this.getResults();
    },

    params() {
      this.getResults();
      this.updateQuery();
    },
  },

  methods: {
    async getResults() {
      this.results = null;
      if (this.params.query) {
        const response = await apiClient.get('v2/search', { params: { query: this.params.query, searchable_type: this.params.searchableTypes, page: this.$route.params.page } });
        this.results = response.data.documents;
        this.totalPages = response.data.meta.total_pages;
      }
    },

    updateQuery() {
      const query = { query: this.params.query.trim(), searchable_type: this.params.searchableTypes };
      if (this.$route.query.query) {
        const querySearchableTypes = this.$route.query.searchable_type;
        const currentSearchableTypes = typeof querySearchableTypes === 'string' ? [querySearchableTypes] : querySearchableTypes;
        if (
          JSON.stringify(currentSearchableTypes) === JSON.stringify(this.params.searchableTypes)
          && decodeURI(this.$route.query.query) === this.params.query
        ) return;
      }
      this.$router.push({path: '/search', query });
    },

    resultName(result) {
      let name;
      if (result.searchable_type === 'Webinar') name = `Вебинар: ${result.searchable.name}`;
      else if (result.searchable_type === 'Course') name = `Тариф: ${result.searchable.name}`;
      else if (result.searchable_type === 'Unit') name = `Тариф: ${result.searchable.course.name} – ${result.searchable.name}`;
      else if (result.searchable_type === 'Lesson') {
        name = `Тариф: ${result.searchable.course.name} – ${result.searchable.origin_unit.name} – ${result.searchable.title}`;
      }
      else if (result.searchable_type === 'Step') {
        name = `Тариф: ${result.searchable.course.name} – ${result.searchable.origin_unit.name} – ${result.searchable.origin_lesson.title}`;
      }
      else if (result.searchable_type === 'Post') name = `Тариф: ${result.searchable.course.name} – Дневник курса`;
      else if (result.searchable_type === 'Product') name = `Курс: ${result.searchable.name}`;
      return name;
    },

    resultTextFragments(result) {
      let text;
      let startQuery;
      let searchable_texts = [];
      const query = this.params.query;

      if (result.searchable_type === 'Course' || result.searchable_type === 'Unit') searchable_texts = ['description', 'memo', 'name'];
      else if (result.searchable_type === 'Lesson') searchable_texts = ['description', 'title'];
      else if (result.searchable_type === 'Step' || result.searchable_type === 'Post') searchable_texts = ['text'];
      else if (result.searchable_type === 'Webinar') searchable_texts = ['description', 'name'];
      else if (result.searchable_type === 'Product') searchable_texts = ['description', 'name', 'external_url'];

      searchable_texts.forEach((searchable_text) => {
        if (result.searchable[searchable_text]) {
          if (result.searchable[searchable_text].toLowerCase().includes(query.toLowerCase())) {
            text = result.searchable[searchable_text];
            startQuery = result.searchable[searchable_text].toLowerCase().indexOf(query.toLowerCase());
          }
        }
      });

      let fragments;
      if (text) {
        const startText = startQuery - 50 < 0 ? 0 : startQuery - 50;
        const endQuery = startQuery + query.length;
        const endText = endQuery + 50 > text.length ? text.length : endQuery + 50;
        fragments = [text.slice(startText, startQuery), text.slice(startQuery, endQuery), text.slice(endQuery, endText)];
        fragments[0] = `${startText > 0 ? '...' : ''}${fragments[0]}`;
        fragments[2] = `${fragments[2]}${endText < text.length ? '...' : ''}`;
      }

      return fragments;
    },

    resultLink(result) {
      let link = this.$route.fullPath;
      if (result.searchable_type === 'Course') link = `/courses/${result.searchable.id}/edit`;
      else if (result.searchable_type === 'Unit') link = `/units/${result.searchable.id}/edit?course=${result.searchable.course.id}`;
      else if (result.searchable_type === 'Lesson') {
        link = `lessons/${result.searchable.id}/step/${result.searchable.first_step_id}?course=${result.searchable.course.id}&unit=${result.searchable.origin_unit.id}`;
      }
      else if (result.searchable_type === 'Step' && result.searchable.origin_lesson && result.searchable.origin_lesson.id) {
        const lessonId = result.searchable.origin_lesson.id;
        const stepId = result.searchable.id;
        const courseId = result.searchable.course.id;
        const unitId = result.searchable.origin_unit.id;
        link = `/lessons/${lessonId}/step/${stepId}?course=${courseId}&unit=${unitId}`;
      }
      else if (result.searchable_type === 'Post') link = `/courses/${result.searchable.course_id}/edit?tab=posts`;
      else if (result.searchable_type === 'Webinar') link = `/webinars/${result.searchable.id}/edit`;
      else if (result.searchable_type === 'Product') link = `/courses/products/${result.searchable.id}/edit`;
      return link;
    },

    goToPage(page) {
      if (this.$route.params.page != page) {
        this.$router.push({ params: { page: page }, query: this.$route.query });
      }
    },
  },
};
</script>
