<template>
  <div class="container">
    <h2 class="mb-4">Настройка выгрузки</h2>
    <template v-if="report">
      <ReportsForm :report="report"/>
      <div class="d-flex">
        <router-link class="btn btn-outline-secondary me-4" to="/reports">Отмена</router-link>
        <button class="btn btn-primary" @click="changeReport">Сохранить</button>
      </div>
    </template>
    <div v-else class="loader text-center">
      <b-spinner/>
    </div>
  </div>
</template>

<script>
import ReportsForm from '../../components/reports/ReportsForm.vue';
import { apiClient } from '../../custom_functions/api_client';
import { makeToast } from '../../custom_functions/custom_functions';
import { toString } from 'cronstrue';
import { mapGetters } from 'vuex';

export default {
  name: 'ReportsEdit',

  components: {
    ReportsForm,
  },

  computed: {
    ...mapGetters('reports', { report: 'getReport' }),
  },

  created() {
    this.$store.dispatch('reports/fetchReport', this.$route.params.id);
  },

  methods: {
    async changeReport() {
      let correctData = true;
      if (!this.report.name) {
        makeToast('Введите имя файла', 'danger');
        correctData = false;
      }
      if (!this.report.cron || toString(this.report.cron, { throwExceptionOnParseError: false }).includes('error')) {
        makeToast('Введите периодичность выгрузки в cron-формате', 'danger');
        correctData = false;
      }
      if (!this.report.sql) {
        makeToast('Введите SQL запрос', 'danger');
        correctData = false;
      }
      if (correctData) {
        await apiClient.patch(`/v2/google_reports/${this.$route.params.id}`, { report: this.report });
        this.$router.push('/reports');
        makeToast('Настройки выгрузки изменены', 'success');
      }
    },
  },
};
</script>
