import { apiClient } from '@/custom_functions/api_client';
import { validateCourse } from '@/custom_functions/validation_functions';

export default {
  namespaced: true,

  state: {
    participations: null,
    participationsTotalPages: null,
    course: null,
    productOwners: null,
    errors: {},
    conflict: null,
    httpProcess: false,
  },

  getters: {
    getCourse: (state) => state.course,

    getUnit: (state) => (unitId) =>
      state.course && unitId
        ? state.course.visible_units.find((unit) => +unit.id === +unitId)
        : null,

    getCourseLink: (state) =>
      state.course ? `/courses/${state.course.url}/edit?tab=content` : '',

    getUnitLink: (state) => (id) =>
      state.course ? `/units/${id}/edit?course=${state.course.url}` : '',

    getParticipations: (state) => state.participations,

    getParticipationsTotalPages: (state) => state.participationsTotalPages,

    getProductOwners: (state) => state.productOwners,

    getProductOwnerById: (state) => (id) =>
      state.productOwners.find((po) => po.id === id),

    getErrors: (state) => state.errors,

    getHasErrors: (state) => !!Object.keys(state.errors).length,

    getConflict: (state) => state.conflict,

    getHttpProcess: (state) => state.httpProcess,
  },

  actions: {
    async fetchCourse({ commit }, courseUrl) {
      if (courseUrl) {
        commit('updateCourse', null);
        let response = await apiClient.get(`/v2/courses/${courseUrl}`);
        commit('updateCourse', {
          ...response.data.course,
          recommended_courses_ids: [],
          recommended_webinar_ids: [],
        });
      }
    },

    createCourse({ commit }) {
      const course = {
        icon_url: null,
        preorder: false,
        publication_status: 'draft',
        can_use_installment: true,
        expire_days: 730,
        memo: '',
        extended_description: '',
        direction_ids: [],
      };
      commit('updateCourse', course);
    },

    async fetchParticipations({ commit }, payload) {
      if (payload && payload.id) {
        const params = { page: payload.page, search: payload.search };
        const response = await apiClient.get(
          `/v2/courses/${payload.id}/participations`,
          {
            params,
          },
        );

        commit('updateParticipations', response.data.participations);
        commit(
          'updateParticipationsTotalPages',
          response.data.meta.total_pages,
        );
      }
    },

    async fetchProductOwners({ commit }, query) {
      const params = {
        role: 'slurm_manager',
      };
      if (query) params['q[first_name_or_last_name_cont]'] = query;
      const response = await apiClient.get('/v2/users', {
        params,
      });
      commit('updateProductOwners', response.data.users);
    },

    async saveCourse({ commit, state, dispatch }) {
      dispatch('validateCourse');
      if (!Object.keys(state.errors).length) {
        commit('updateHttpProcess', true);
        try {
          const response = await apiClient.post('/v2/courses', {
            course: state.course,
          });
          commit('updateCourse', response.data.course);
        }
        finally {
          commit('updateHttpProcess', false);
        }
      }
    },

    async editCourse({ commit, state, dispatch }) {
      dispatch('validateCourse');
      if (!Object.keys(state.errors).length) {
        try {
          commit('updateHttpProcess', true);
          const response = await apiClient.patch(
            `/v2/courses/${state.course.id}`,
            {
              course: state.course,
            },
          );
          const newCourse = Object.assign(
            {},
            state.course,
            response.data.course,
          );
          commit('updateCourse', newCourse);
        } catch (e) {
          if (e.response.status === 409)
            commit('updateConflict', e.response.data);
          throw e;
        } finally {
          commit('updateHttpProcess', false);
        }
      }
    },

    validateCourse({ commit, state }) {
      commit('updateErrors', validateCourse(state.course));
    },
  },

  mutations: {
    updateCourse(state, course) {
      state.course = course;
    },

    updateParticipations(state, participations) {
      state.participations = participations;
    },

    updateParticipationsTotalPages(state, participationsTotalPages) {
      state.participationsTotalPages = participationsTotalPages;
    },

    updateProductOwners(state, productOwners) {
      state.productOwners = productOwners;
    },

    updateErrors(state, errors) {
      state.errors = errors;
    },

    updateConflict(state, value) {
      state.conflict = value;
    },

    updateHttpProcess(state, value) {
      state.httpProcess = value;
    },
  },
};
