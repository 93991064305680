<template>
  <div>
    <label for="course-url" class="mb-2">URL (адрес страницы)*</label>
    <input
      id="course-url"
      v-model.trim="url"
      :class="errors && 'is-invalid'"
      type="text"
      class="form-control"
    />
    <span v-for="(error, index) in errors" :key="index" class="invalid-feedback">
      {{ error }}
    </span>
    <small class="form-text text-muted">
      edu.slurm.io/courses/{{ !errors && url ? url : 'название ссылки' }}
    </small>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const props = defineProps({
  courseItemType: {
    type: String,
    validator: (value) => ['container', 'course'].includes(value),
  },
  modelValue: { type: String },
  errors: { type: Array },
});

const emit = defineEmits(['update:modelValue']);

const course = computed(() => {
  return store.getters[
    props.courseItemType === 'container'
      ? 'container/getContainer'
      : 'course/getCourse'
  ];
});

const errors = computed(() => {
  if (props.courseItemType) {
    return store.getters[
      props.courseItemType === 'container'
        ? 'container/getErrors'
        : 'course/getErrors'
    ].url;
  }
  return props.errors;
});

const url = computed({
  get: () => {
    if (props.courseItemType) return course.value.url;
    return props.modelValue;
  },
  set: (value) => {
    if (props.courseItemType) course.value.url = value;
    emit('update:modelValue', value);
  },
});
</script>
