import { makeToast } from '@/custom_functions/custom_functions';

export default function validateWebinar(webinar) {
  if (
    webinar.name
    && webinar.publication_status
    && webinar.start_time
    && webinar.end_time
    && webinar.data.url
    && (
      (webinar.type === 'PlainWebinar' && (webinar.image || webinar.thumbnail_url))
      || (webinar.type === 'YoutubeWebinar' && webinar.data.url.includes('https://www.youtube.com/watch?v='))
    )
  ) return true;
  if (!webinar.name) makeToast('Введите название вебинара', 'danger');
  if (!webinar.publication_status) makeToast('Выберите статус публикации вебинара', 'danger');
  if (!webinar.start_time) makeToast('Введите дату и время начала вебинара', 'danger');
  if (!webinar.end_time) makeToast('Введите дату и время окончания вебинара', 'danger');
  if (webinar.type === 'PlainWebinar' && !webinar.image && !webinar.thumbnail_url) makeToast('Выберите файл с баннером вебинара', 'danger');

  if (!webinar.data.url) makeToast('Введите ссылку на трансляцию вебинара', 'danger');
  else if (webinar.type === 'YoutubeWebinar' && !webinar.data.url.includes('https://www.youtube.com/watch?v='))
    makeToast('Некорректная ссылка на Youtube-трансляцию вебинара', 'danger');
  return false;
}
