<template>
  <div>
    <b-tabs pills content-class="mt-3" nav-wrapper-class="position-relative">
      <b-tab title="По урокам" active>
        <b-card-text>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Студент / Email</th>
                <th scope="col">Логин</th>
                <th scope="col">Урок</th>
                <th scope="col" class="rating-col">Оценка / Дата</th>
                <th scope="col">Комментарий</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(review, i) in lessonReviews" :key="i">
                <td>
                  <p v-show="review.user.full_name" class="mb-0">
                    {{ review.user.full_name }}
                  </p>
                  <p class="text-muted">{{ review.user.email }}</p>
                </td>
                <td>
                  <p>{{ review.user.login }}</p>
                </td>
                <td>{{ review.reviewable.lesson.name }}</td>
                <td>
                  <div v-if="review.rating === 1">
                    <img
                      src="@/assets/smile-negative.svg"
                      alt="Недовольный смайл"
                      height="16"
                      class="me-2"
                    />
                    <span>Плохо</span>
                  </div>

                  <div v-else-if="review.rating === 2">
                    <img src="@/assets/smile-neutral.svg" alt="Нейтральный смайл" height="16"
                         class="me-2" />
                    <span>Нормально</span>
                  </div>

                  <div v-else-if="review.rating === 3">
                    <img src="@/assets/smile-fire.svg" alt="Огонь смайл" height="16"
                         class="me-2" />
                    <span>Отлично</span>
                  </div>

                  <p class="text-muted">{{ formatDateTime(review.created_at) }}</p>
                </td>
                <td>
                  <p>{{ review.content }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-text>
      </b-tab>

      <b-tab title="О курсе">
        <b-card-text>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Студент / Email</th>
                <th scope="col">Логин</th>
                <th scope="col" class="rating-col"> Оценка / Дата</th>
                <th scope="col">Комментарий</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(review, i) in courseReviews" :key="i">
                <td>
                  <p v-show="review.user.full_name" class="mb-0">
                    {{ review.user.full_name }}
                  </p>
                  <p class="text-muted">{{ review.user.email }}</p>
                </td>
                <td>
                  <p>{{ review.user.login }}</p>
                </td>
                <td>
                  <div v-if="review.rating === 1">
                    <img src="@/assets/smile-negative.svg" alt="Недовольный смайл" height="16"
                         class="me-2" />
                    <span>Плохо</span>
                  </div>

                  <div v-else-if="review.rating === 2">
                    <img src="@/assets/smile-neutral.svg" alt="Нейтральный смайл" height="16"
                         class="me-2" />
                    <span>Нормально</span>
                  </div>

                  <div v-else-if="review.rating === 3">
                    <img src="@/assets/smile-fire.svg" alt="Огонь смайл" height="16"
                         class="me-2" />
                    <span>Отлично</span>
                  </div>

                  <p class="text-muted">{{ formatDateTime(review.created_at) }}</p>
                </td>
                <td>
                  <p>{{ review.content }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card-text>
      </b-tab>

      <template #tabs-end>
        <button
          type="button"
          class="btn btn-outline-primary position-absolute download-reviews-btn"
          @click="downloadCourseReviews"
        >
          Скачать отчёт
        </button>
      </template>
    </b-tabs>
  </div>
</template>

<script setup>
import { formatDateTime } from '@/custom_functions/custom_functions';
import { apiClient } from '@/custom_functions/api_client';

import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const reviews = ref(null);

const courseReviews = computed(() => {
  if (reviews.value) return reviews.value.filter((review) => review.reviewable_type === 'Course');
  else return null;
});

const lessonReviews = computed(() => {
  if (reviews.value) return reviews.value.filter((review) => review.reviewable_type === 'Step');
  else return null;
});


(async function () {
  const response = await apiClient.get(`/v2/courses/${route.params.id}/reviews`, { params: { } });
  reviews.value = response.data.reviews;
}());

async function downloadCourseReviews() {
  const response = await apiClient.get(`/v2/courses/${route.params.id}/reviews.xlsx`, {
    responseType: 'arraybuffer',
  });
  const blob = new Blob([response.data], {
    type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = document.createElement('a');
  link.download = `Отзывы о курсе ${route.params.id}.xlsx`;
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
  link.remove();
}
</script>

<style scoped>
.download-reviews-btn {
  right: 0;
}
.rating-col {
  min-width: 180px;
}
</style>
