<template>
  <slot :action="openModal">
    <button class="btn btn-primary" @click="openModal">
      <font-awesome-icon icon="copy" />
      Дублировать
    </button>
  </slot>
  <b-modal v-model="shownModal" title="Дублировать тариф" hide-footer>
    <p>
      Создадим новый тариф с полной копией разделов:<br />
      Основное, Цена, Продвижение, Содержание.<br />
      Подойдет для создания нового тарифа или потока.<br />
    </p>
    <form v-if="productOptions && courses" @submit.prevent="copyCourse">
      <CustomSelect
        :id="`new-course-product-ids-${course.id}`"
        v-model:model-value="productId"
        :options="productOptions"
        :errors="errors.product_ids"
        :search="(query) => store.dispatch('products/fetchProducts', { search: query })"
        label="В какой курс добавить этот тариф"
        tip="Выберите из существующих курсов"
        class="mb-3"
        required
      />
      <div class="mb-3">
        <label :for="`new-course-tariff-name-${course.id}`" class="form-label">Тариф</label>
        <input
          :id="`new-course-tariff-name-${course.id}`"
          v-model.trim="newCourse.tariff_name"
          :class="errors.tariff_name && 'is-invalid'"
          type="text"
          class="form-control"
        />
        <span v-for="error in errors.tariff_name" :key="error" class="invalid-feedback">
          {{ error }}
        </span>
        <span class="form-text">Например, Комфорт</span>
      </div>
      <div class="mb-3">
        <label :for="`new-course-stream-name-${course.id}`" class="form-label">Поток</label>
        <input
          :id="`new-course-stream-name-${course.id}`"
          v-model.trim="newCourse.stream_name"
          :class="errors.stream_name && 'is-invalid'"
          type="text"
          class="form-control"
        />
        <span v-for="error in errors.stream_name" :key="error" class="invalid-feedback">
          {{ error }}
        </span>
        <span class="form-text">Например, Поток 3. Макс. 10 символов</span>
      </div>
      <CourseUrl v-model:model-value="newCourse.url" :errors="errors.url" class="mb-3" />
      <button class="btn btn-outline-primary w-100" type="submit">Создать новый тариф</button>
    </form>
    <div v-else class="text-center my-5">
      <b-spinner />
    </div>
  </b-modal>
</template>

<script setup>
import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';

import CustomSelect from '@/components/CustomSelect';
import CourseUrl from '@/components/courses/CourseUrl';

import { useCustomSelectOptions } from '@/composables/use_custom_select_options';

import { validateCourse } from '@/custom_functions/validation_functions';
import { makeToast } from '../../custom_functions/custom_functions';

const store = useStore();

const props = defineProps({ course: { type: Object, required: true } });

const shownModal = ref(false);

function openModal() {
  shownModal.value = true;
  if (!products.value) store.dispatch('products/fetchProducts');
  if (!courses.value) store.dispatch('courses/fetchCoursesOmitPagination');
  if (
    props.course.product_ids.length &&
    (!product.value || product.value.id !== props.course.product_ids[0])
  )
    store.dispatch('product/fetchProduct', props.course.product_ids[0]);
}

const newCourse = reactive({
  product_ids: props.course.product_ids,
  tariff_name: null,
  stream_name: null,
  url: null,
});

const productId = computed({
  get: () => (newCourse.product_ids ? newCourse.product_ids[0] : null),
  set: (value) => (newCourse.product_ids = value ? [value] : null),
});

const errors = ref({});

const products = computed(() => store.getters['products/getProducts']);
const product = computed(() => store.getters['product/getProduct']);
const courses = computed(() => store.getters['courses/getCoursesOmitPagination']);

const productOptions = computed(() => {
  if (!products.value || (props.course.product_ids.length && !product.value)) return null;

  const list = products.value;
  if (product.value && list.every((item) => item.id !== product.value.id)) {
    list.push(product.value);
  }

  return useCustomSelectOptions(list, 'id', 'name');
});

const hasErrors = computed(
  () =>
    errors.value.product_ids ||
    errors.value.tariff_name ||
    errors.value.stream_name ||
    errors.value.url,
);

async function copyCourse() {
  errors.value = validateCourse(newCourse);
  if (!hasErrors.value) {
    await store.dispatch('courses/copyCourse', {
      sourceCourseId: props.course.id,
      params: newCourse,
    });
    makeToast('Создан новый тариф', 'success');
    shownModal.value = false;
  }
}

watch(newCourse, () => {
  if (hasErrors.value) errors.value = validateCourse(newCourse);
});

watch(shownModal, (value) => {
  if (!value) newCourse.tariff_name = newCourse.stream_name = newCourse.url = null;
});
</script>
