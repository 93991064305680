<template>
  <b-toast
    v-model="visible"
    no-auto-hide
    title="Уведомление от учебного центра «Слёрм»"
    variant="success"
  >
    <p>{{ comment }}</p>
    <div class="d-flex align-items-baseline">
      <button class="btn btn-success btn-sm me-3" @click="revertAction">
        <font-awesome-icon icon="undo"/>
        {{ buttonText }}
      </button>
      <span class="fw-bold">{{ timer }} сек.</span>
    </div>
  </b-toast>
</template>

<script setup>
import { ref, watch, onBeforeUnmount } from 'vue';

const props = defineProps({
  modelValue: { type: Boolean, required: true },
  comment: { type: String, default: 'Вы можете отменить последнее действие' },
  buttonText: { type: String, default: 'Отменить' },
  time: { type: Number, default: 10 },
});
const emit = defineEmits(['update:modelValue', 'action', 'revert']);

const visible = ref(props.modelValue);

watch(() => props.modelValue, (value) => {
  if (value) visible.value = value;
});

let timeout;

let interval;

const timer = ref(null);

function callAction() {
  if (timeout) {
    visible.value = false;
    emit('action');
    timeout = null;
  }
}

function startTimer() {
  timeout = setTimeout(callAction, 10000);
  timer.value = props.time;
  interval = setInterval(() => {
    if (timer.value > 0) timer.value--;
    else {
      clearInterval(interval);
      timer.value = null;
    }
  }, 1000);
}

if (visible.value) startTimer();

watch(visible, (value) => {
  emit('update:modelValue', value);
  if (value) startTimer();
  else callAction();
});

onBeforeUnmount(callAction);

window.addEventListener('beforeunload', callAction);

function revertAction() {
  visible.value = false;
  emit('revert');
  clearTimeout(timeout);
  timeout = null;
}
</script>

