import {
  getTypeByDisplayedType,
  isCombinationOfTypesCorrect,
} from '@/custom_functions/course_types';
import validateNotEmptyField from '../validate_not_empty_field';

export default function validateCourseTypes(displayedType, courseType) {
  const errors = validateNotEmptyField(displayedType);
  if (displayedType && !getTypeByDisplayedType(displayedType)) {
    errors.push('Выбранного типа не существует');
  }
  else if (displayedType && !isCombinationOfTypesCorrect(displayedType, courseType)) {
    errors.push('Тип, отображаемый студенту, не соответствует техническому типу. Выберите тип из предложенного списка или обратитесь к разработчикам, чтобы изменить технический тип.');
  }
  return errors;
}
