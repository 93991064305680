<template>
  <div>
    <b-button variant="outline-secondary" class="text-nowrap" @click="convert"
    >Конвертировать в {{ newTypeName }}</b-button
    >
  </div>
</template>

<script>
import { validateCourse } from '@/custom_functions/validation_functions';
import { makeToast } from '../../custom_functions/custom_functions';

export default {
  name: 'ConversionButton',

  props: {
    course: Object,
    newType: String,
    newTypeName: String,
    newDisplayedType: String,
  },

  data: () => {
    return { errors: null };
  },

  methods: {
    async convert() {
      let response = await this.$apiClient.post(`v2/courses/${this.course.id}/convert`, {
        new_type: this.newType,
      });

      const changedCourse = { ...response.data.course };
      changedCourse.displayed_type = this.newDisplayedType;
      changedCourse.date_to = null;
      changedCourse.date_from = null;
      changedCourse.zoom_url = null;
      if (this.newType === 'demo') changedCourse.price = 0;

      response = await this.$apiClient.patch(`/v2/courses/${this.course.id}`, {
        course: changedCourse,
      });
      this.$emit('courseConverted', response.data.course);
      this.errors = validateCourse(this.course);

      if (Object.keys(this.errors).length) {
        makeToast(
          `Курс конвертирован в ${this.newTypeName}, но для завершения необходимо корректно заполнить отмеченные поля`,
          'warning',
        );
      } else makeToast(`Курс конвертирован в ${this.newTypeName}`, 'success');
    },
  },
};
</script>
