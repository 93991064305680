<template>
  <table class="table table-sm table-hover">
    <thead>
      <tr>
        <th class="ps-3 table-col-1 table-cell align-middle">ID</th>
        <th class="table-col-2 table-cell align-middle">
          <TableFilter
            v-model:model-value="displayedType"
            :values="displayedTypes"
            :format-function="formatDisplayedType"
            :disabled="httpProcess"
            title="Комплект/подписка"
          />
        </th>
        <th class="table-col-3 table-cell align-middle">
          <TableFilter
            v-model:model-value="publicationStatus"
            :values="publicationStatuses"
            :format-function="formatPublicationStatus"
            :disabled="httpProcess"
            title="Статус"
          />
        </th>
        <th class="table-col-4 table-cell align-middle">Директор</th>
        <th class="pe-3 table-col-5 table-cell align-middle"/>
      </tr>
    </thead>
    <tbody v-if="containers?.length && productOwners">
      <ContainersTableRow
        v-for="container in containers"
        :key="container.id"
        :container="container"
      />
    </tbody>
  </table>
  <div v-if="containers && !containers.length" class="loader text-center fs-3 text-secondary">
    Комплекты или подписки не найдены
  </div>
  <div v-else-if="!containers || !productOwners" class="loader text-center">
    <b-spinner />
  </div>
  <Pagination v-if="containers" :total-pages="totalPages" :items-count="containers.length" />
</template>

<script setup>
import Pagination from '@/components/Pagination';
import TableFilter from '@/components/TableFilter';
import ContainersTableRow from '@/components/courses/ContainersTableRow';

import { useProductOwners } from '@/composables/courses/use_product_owners';
import { usePublicationStatusFilter } from '@/composables/courses/use_publication_status_filter';
import { useDisplayedTypeFilter } from '@/composables/courses/use_displayed_type_filter';

import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const containers = computed(() => store.getters['containers/getContainers']);
const totalPages = computed(() => store.getters['containers/getContainersTotalPages']);
const httpProcess = computed(() => store.getters['containers/getHttpProcess']);

const {
  publicationStatus,
  publicationStatuses,
  formatPublicationStatus,
} = usePublicationStatusFilter();

const {
  displayedTypes,
  displayedType,
  formatDisplayedType,
} = useDisplayedTypeFilter('containers');

const { productOwners } = useProductOwners();
</script>

<style scoped>
.table-col-1 {
  width: 55px;
}
.table-col-2 {
  width: 600px;
}
.table-col-3 {
  width: 160px;
}
.table-col-4 {
  width: 320px;
}
.table-col-5 {
  width: 65px;
}
</style>

