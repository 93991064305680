<template>
  <div v-if="value1.length < 300 && value2.length < 300">
    Поле <b>{{ fieldName }}</b> изменилось с <i>{{value1}}</i> на <i>{{value2}}</i>
  </div>
  <div v-else class="conflict-diff">
    Поле <b>{{ fieldName }}</b> изменилось:

    <div class="card card-body bg-light d-block" v-html="prettyDiff"/>
  </div>
</template>

<script>
import Diff from 'text-diff';

export default {
  name: 'ConflictFields',
  props: {
    field: String,
    value1: String,
    value2: String,
  },

  computed: {
    prettyDiff() {
      const diff = new Diff();
      const textDiff = diff.main(this.value1, this.value2);
      return diff.prettyHtml(textDiff);
    },

    // TODO: Сделать нормально
    fieldName() {
      switch (this.field) {
      case 'name':
        return 'Название';
      case 'extended_description':
        return 'Расширенное описание';
      case 'description':
        return 'Описание';
      case 'memo':
        return 'Памятка';
      default:
        return this.field;
      }
    },
  },
};
</script>

<style lang="scss">
.conflict-diff {
  ins {
    text-decoration: none;
    background-color: #c7f0d2;
  }

  del {
    text-decoration: none;
    background-color: #fbe9eb;
  }
}
</style>
