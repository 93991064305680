<template>
  <div class="px-0 px-md-3 py-2 border-bottom">
    <button class="btn-block btn text-start d-flex align-items-baseline p-0" @click="visibleUnits = !visibleUnits">
      <span class="h5">
        <font-awesome-icon :icon="visibleUnits ? 'angle-up' : 'angle-down'" class="me-3" />
      </span>
      <div>
        <h3 class="h5 fw-bold">{{ course.name }}</h3>
        <span v-if="course.awaiting_attempts_count.all" class="text-primary badge rounded-pill border-primary border me-2">
          {{ course.awaiting_attempts_count.all }} ждут проверки
        </span>
        <span v-else class="badge rounded-pill text-secondary border border-secondary">Нет непроверенных заданий</span>
        <span
          v-if="course.awaiting_attempts_count.rotting"
          :id="`rotting-attempts-course-${course.url}`"
          class="text-warning badge rounded-pill border-warning border me-2"
        >
          {{ course.awaiting_attempts_count.rotting }}
          <font-awesome-icon icon="circle-exclamation" />
          <b-tooltip :target="`rotting-attempts-course-${course.url}`" triggers="hover">Меньше суток до окончания срока проверки</b-tooltip>
        </span>
        <span
          v-if="course.awaiting_attempts_count.expired"
          :id="`expired-attempts-course-${course.url}`"
          class="text-danger badge rounded-pill border-danger border me-2"
        >
          {{ course.awaiting_attempts_count.expired }}
          <font-awesome-icon icon="circle-exclamation" />
          <b-tooltip :target="`expired-attempts-course-${course.url}`" triggers="hover">Время для проверки истекло</b-tooltip>
        </span>
      </div>
    </button>
    <b-collapse v-model="visibleUnits" class="mt-2">
      <div class="d-flex align-items-baseline filter-input mb-2 mb-lg-3">
        <label for="filter" class="me-2">Показать:</label>
        <CustomSelect id="filter" v-model:model-value="filter" :options="filters"
                      class="mb-3" />
      </div>
      <ol class="list-group">
        <li v-for="(unit, index) in filteredUnits" :key="unit.id" class="list-group-item p-0 border-0">
          <ReviewerUnit :unit="unit" :class="index !== filteredUnits.length - 1 && ['mb-2', 'mb-lg-3']" />
        </li>
      </ol>
    </b-collapse>
  </div>
</template>

<script>
import CustomSelect from '@/components/CustomSelect';
import ReviewerUnit from '@/components/reviewers/ReviewerUnit.vue';

export default {
  name: 'ReviewerCourse',

  components: {
    ReviewerUnit,
    CustomSelect,
  },

  data() {
    return {
      visibleUnits: false,
      filters: [
        { value: '', name: 'Все' },
        { value: 'all', name: 'Непроверенные' },
        { value: 'rotting', name: 'Скоро истекает срок проверки' },
        { value: 'expired', name: 'Истёк срок проверки' },
      ],
      filter: '',
    };
  },

  computed: {
    course() {
      return this.$store.getters['reviewers/getReviewer'].course;
    },

    filteredUnits() {
      const units = this.course.units.map((unit) => ({ ...unit }));
      return units.filter((unit, unitIndex) => {
        unit.lessons = unit.lessons.map((lesson) => ({ ...lesson }));
        unit.lessons = unit.lessons.filter((lesson, lessonIndex) => {
          lesson.steps = lesson.steps.map((step) => ({ ...step }));
          lesson.steps = lesson.steps.filter((step) => !this.filter || step.awaiting_attempts_count[this.filter]);
          lesson.index = lessonIndex;
          return !this.filter || lesson.awaiting_attempts_count[this.filter];
        });
        unit.index = unitIndex;
        return !this.filter || unit.awaiting_attempts_count[this.filter];
      });
    },
  },

  created() {
    this.visibleUnits = this.$route.query.course === this.course.url;
  },
};
</script>
