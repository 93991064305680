<template>
  <div>
    <div class="row">
      <div class="col-sm">
        <draggable v-model:model-value.lazy="step.data.variants.questions">
          <template #item="{element}">
            <div class="d-flex card flex-row align-items-center justify-content-between mb-3 p-3">
              <font-awesome-icon icon="grip-vertical" class="me-2 text-muted drag-icon" />
              <input v-model="element.text" class="form-control" placeholder="Вопрос" />
              <font-awesome-icon icon="equals" class="equals text-muted matching-icon" />
            </div>
          </template>
        </draggable>
      </div>

      <div class="col-sm">
        <draggable v-model:model-value="step.data.variants.answers">
          <template #item="{element}">
            <div class="d-flex card flex-row align-items-center justify-content-between mb-3 p-3">
              <font-awesome-icon icon="grip-vertical" class="me-2 text-muted drag-icon" />
              <input v-model="element.text" class="form-control" placeholder="Ответ" />
              <button class="ms-2 btn btn-link text-danger" @click.prevent="deleteVariant(element)">
                <font-awesome-icon icon="xmark" />
              </button>
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <button class="btn btn-outline-primary btn-sm" @click="addVariant">+ добавить вариант</button>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'MatchStep',

  props: { step: { type: Object, required: true } },

  methods: {
    deleteVariant(element) {
      this.step.data.variants.answers = this.step.data.variants.answers.filter((answer) => {
        if (JSON.stringify(answer) !== JSON.stringify(element)) return answer;
      });
      const questionIndex = this.step.data.variants.answers.indexOf(element);
      this.step.data.variants.questions.splice(questionIndex, 1);
    },

    async addVariant() {
      this.step.data.variants.questions.push({ id: uuidv4(), text: '' });
      this.step.data.variants.answers.push({ id: uuidv4(), text: '' });
    },
  },
};
</script>

<style>
.drag-icon {
  cursor: grab;
}

.matching-icon {
  position: absolute;
  right: -32px;
}

.equals {
  margin-right: 12px;
}
</style>