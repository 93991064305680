import moment from 'moment';
import store from '../store';

// format functions
export function formatDate(value) {
  if (value) {
    moment.locale('ru');
    return moment(value).utcOffset('GMT+03:00').format('DD.MM.YYYY');
  }
}

export function formatDateTime(value) {
  if (value) {
    moment.locale('ru');
    return moment(value).utcOffset('GMT+03:00').format('DD.MM.YYYY в HH:mm');
  }
}

export function formatPrice(value) {
  if (value && value > 0) {
    const options = {
      style: 'currency',
      currency: 'RUB',
      maximumFractionDigits: 0,
    };
    return parseInt(value).toLocaleString('ru-RU', options);
  }
}

export function formatLessonStatus(status) {
  if (status === 'published') {
    return 'Опубликован';
  } else if (status === 'draft') {
    return 'Черновик';
  } else if (status === 'hidden') {
    return 'Приватный';
  } else {
    return '';
  }
}

export function formatElementsCount(value) {
  if (value % 10 === 1 && value % 100 !== 11) return `Выбран ${value} элемент`;
  else if (
    value % 10 >= 2 &&
    value % 10 <= 4 &&
    (value % 100 < 12 || value % 100 > 14)
  ) {
    return `Выбрано ${value} элемента`;
  } else return `Выбрано ${value} элементов`;
}

export function formatSessionState(value) {
  switch (value) {
  case 'idle':
    return 'Не используется';
  case 'preparing':
    return 'Создается';
  case 'running':
    return 'Запущен';
  case 'stopping':
    return 'Удаляется';
  case 'finished':
    return 'Завершен';
  case 'failed':
    return 'Завершен с ошибкой';
  case 'on_check':
    return 'Отправлен на проверку';
  default:
    return value;
  }
}

export function formatStepType(value) {
  if (value === 'TextStep') return 'Текст';
  if (value === 'VideoStep') return 'Видео';
  if (value === 'TextTaskStep') return 'Текстовая задача';
  if (value === 'QuizStep') return 'Тест';
  if (value === 'SortStep') return 'Сортировка';
  if (value === 'MatchStep') return 'Сопоставление';
  if (value === 'InteractStep') return 'Интерактив';
  if (value === 'DeferredStep') return 'Ручная проверка';
  return 'Неизвестный тип';
}

export function formatAnswersCount(value) {
  const lastDigit = +value % 10;
  const twoLastDigits = +value % 100;
  if (
    lastDigit === 0 ||
    (lastDigit >= 5 && lastDigit <= 9) ||
    (twoLastDigits >= 11 && twoLastDigits <= 14)
  )
    return `${value} решений`;
  if (lastDigit === 1) return `${value} решение`;
  return `${value} решения`;
}
// format functions end

// apiClient functions
export function makeToast(text, variant = null) {
  store.commit('toast/changeToast', { text: text, variant: variant });
}

export function errorHandling(e) {
  if (!('response' in e)) {
    return false;
  }

  const { status } = e.response;
  let errorText = '';
  let toastVariant = 'danger';
  if (status === 404) {
    errorText = 'Ресурс не найден. Ошибка 404.';
  } else if (status === 401) {
    if (document.location.pathname === '/login') {
      return;
    }
    errorText = e.response.data.error;
    toastVariant = 'warning';
    localStorage.removeItem('userRole');
  } else if (status >= 500 && status <= 526) {
    errorText = `Что-то пошло не так. Ошибка ${status}.`;
  } else if (typeof e.response.data.error === 'string') {
    errorText = e.response.data.error;
  } else if (typeof e.response.data.error === 'object') {
    const keys = Object.keys(e.response.data.error);
    keys.forEach((key) => {
      errorText += `${e.response.data.error[key][0]}. Ошибка: ${status}.`;
    });
  } else {
    errorText = `Что-то пошло не так. Ошибка ${status}.`;
  }

  return makeToast(errorText, toastVariant);
}
// apiClient functions end

export function copyText(
  content,
  successText = 'Текст успешно скопирован',
  failedText = 'Не удалось скопировать текст, попробуйте еще раз',
  isText = true,
) {
  if (!navigator.clipboard) {
    makeToast(
      'Копирование недоступно, попробуйте использовать другой браузер',
      'danger',
    );
    return;
  }
  if (isText) {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        makeToast(successText, 'success');
      })
      .catch(() => {
        makeToast(failedText, 'danger');
      });
  } else {
    navigator.clipboard
      .write(content)
      .then(() => {
        makeToast(successText, 'success');
      })
      .catch(() => {
        makeToast(failedText, 'danger');
      });
  }
}

//webinars

export function webinarPostData(webinar) {
  const postData = new FormData();
  postData.append('webinar[name]', webinar.name);
  postData.append('webinar[publication_status]', webinar.publication_status);
  postData.append('webinar[start_time]', webinar.start_time);
  postData.append('webinar[end_time]', webinar.end_time);
  postData.append('webinar[data][url]', webinar.data.url);
  postData.append('webinar[type]', webinar.type);
  if (webinar.description)
    postData.append('webinar[description]', webinar.description);
  if (webinar.speaker_ids)
    webinar.speaker_ids.forEach((speakerId) =>
      postData.append('webinar[speaker_ids][]', speakerId),
    );
  if (webinar.type === 'PlainWebinar' && webinar.image)
    postData.append('webinar[image]', webinar.image);
  return postData;
}

//webinars end

//reviewers
export function attemptIsRotting(createdDate) {
  return new Date() - new Date(createdDate) >= 1000 * 60 * 60 * 24 * 2;
}

export function attemptIsExpired(createdDate) {
  return new Date() - new Date(createdDate) >= 1000 * 60 * 60 * 24 * 3;
}

export function attemptStatusString(attempt) {
  if (attempt.correct === null) return 'Ожидает проверки';
  if (!attempt.correct) return 'Нужна доработка';
  if (attempt.correct) return 'Решение зачтено';
  return '';
}
//reviewers end

// courses
export function readFile(file, cb) {
  const reader = new FileReader();
  reader.onloadend = () => cb(reader.result);
  reader.readAsDataURL(file);
}
// courses end

export function getInteractableRoles(currentUserRole) {
  const roles = [
    'student',
    'corporate_manager',
    'admin',
    'reviewer',
    'slurm_manager',
  ];

  const isAdmin = currentUserRole === 'admin';

  if (!isAdmin) {
    const rolesWithoutAdmin = roles.filter((role) => role !== 'admin');
    return rolesWithoutAdmin;
  }

  return roles;
}
