<template>
  <nav class="sidebar bg-dark bg-gradient">
    <div class="pt-3 px-3 text-center">
      <b-navbar-brand
        to="/"
        class="mr-md-auto"
      >
        <div class="d-flex flex-column align-items-center">
          <img
            src="@/assets/logo.svg"
            height="46"
            width="136"
            alt="Слёрм"
          />
          <span
            v-if="enviroment"
            class="badge rounded-pill text-white logo-badge text-uppercase"
            :class="logoBadgeClass"
          >
            {{ enviroment }}
          </span>
        </div>
      </b-navbar-brand>
    </div>

    <SearchInput class="mb-1 px-2" />

    <ul class="nav flex-column py-2">
      <li
        v-for="item in rolesForCurrentUser"
        :key="item.url"
      >
        <router-link
          :to="item.url"
          class="nav-link text-light"
          :class="isActiveRoute(item.url) ? 'router-link-active' : ''"
        >
          <font-awesome-icon
            class="sidebar-icon"
            :icon="item.icon"
          />
          <span class="ms-2">{{ item.title }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-light"
          href="#"
          @click.prevent="signOut"
        >
          <font-awesome-icon
            class="sidebar-icon"
            icon="right-from-bracket"
          />
          <span class="ms-2">Выход</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import SearchInput from './search/SearchInput.vue';

export default {
  name: 'AdminSidebar',

  components: { SearchInput },

  data() {
    return {
      enviroment: null,
      items: [
        { url: '/courses', icon: 'graduation-cap', title: 'Курсы и тарифы' },
        { url: '/webinars', icon: 'video', title: 'Вебинары' },
        { url: '/stands_sessions', icon: 'stopwatch', title: 'Сессии' },
        { url: '/script_runners', icon: 'sliders-h', title: 'Скрипты' },
        { url: '/zones', icon: 'desktop', title: 'Зоны' },
        { url: '/tags', icon: 'lightbulb', title: 'Рекомендованные курсы' },
        { url: '/tags_policy', icon: 'tag', title: 'Настройка тегов' },
        {
          url: '/directions',
          icon: 'puzzle-piece',
          title: 'Направления курсов',
        },
        { url: '/promo_codes/page_1', icon: 'percent', title: 'Промокоды' },
        { url: '/users', icon: 'user', title: 'Пользователи' },
        { url: '/support', icon: 'comment', title: 'Поддержка' },
        { url: '/companies', icon: 'building', title: 'Компании' },
        { url: '/speakers', icon: 'bullhorn', title: 'Спикеры' },
        { url: '/reviewers', icon: 'clipboard-check', title: 'Ревьюеры' },
        { url: '/notifications/new', icon: 'envelope', title: 'Уведомления' },
        { url: '/orders', icon: 'credit-card', title: 'Заказы' },
        { url: '/reports', icon: 'upload', title: 'Выгрузки' },
      ],
    };
  },

  created() {
    const hostname = window.location.hostname;
    if (hostname === 'lvh.me' || hostname === 'localhost')
      this.enviroment = 'dev';
    else if (hostname === 'admin2.dev.slurm.io') this.enviroment = 'stage';
  },

  computed: {
    logoBadgeClass() {
      let className;
      if (this.enviroment === 'dev') className = 'bg-info';
      else if (this.enviroment === 'stage') className = 'bg-success';
      return className;
    },

    currentUserHasNoInternalRole() {
      return this.$store.state.currentUserRights === null;
    },

    rolesForCurrentUser() {
      const currentUserRole = localStorage.userRole;
      if (currentUserRole === 'admin') {
        return this.items;
      } else {
        return this.items.filter((item) => item.url !== '/directions');
      }
    },
  },

  methods: {
    async signOut() {
      await this.$apiClient.delete('v2/authentication');
      localStorage.removeItem('userRole');
      await this.$router.replace('/login');
    },

    isActiveRoute(url) {
      const regex = new RegExp(`^\\${url}($|\\/|\\?)`, 'gm');
      return regex.test(this.$route.fullPath);
    },
  },
};
</script>

<style scoped>
li > .router-link-active,
li > #router-home.router-link-active.router-link-exact-active {
  background-color: #495057;
  border-left: 4px solid #f8f9fa;
}

li > #router-home.router-link-active {
  background-color: transparent;
  border-left: none;
}

li > .nav-link {
  transition: all 0.3s ease-out;
}

li > .nav-link:hover {
  background-color: #495057;
}

.logo-badge {
  position: relative;
  top: -14px;
}

.sidebar-icon {
  width: 20px;
}
.sidebar {
  height: 100%;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  display: none;
}
</style>
