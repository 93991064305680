<template>
  <template v-if="product">
    <div class="px-3">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h1 class="h4 mb-0">Курс {{ product.name }}</h1>
        <div class="d-flex">
          <router-link
            :to="`/courses/products/${product.id}/edit`"
            class="btn btn-outline-secondary me-3"
          >
             Редактировать курс
          </router-link>
          <router-link :to="`/courses/new?product=${product.id}`" class="btn btn-primary">
            Создать тариф в курсе
          </router-link>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mb-2 pt-1">
        <router-link to="/courses?tab=products" class="btn btn-outline-primary">
          <font-awesome-icon icon="chevron-left" size="sm" class="me-2" />
          Назад в список курсов
        </router-link>
        <CoursesSearch placeholder="Поиск тарифа по названию или ID" />
      </div>
    </div>
    <CoursesTable />
  </template>
  <div v-else class="text-center loader">
    <b-spinner />
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import CoursesSearch from '@/components/courses/CoursesSearch';
import CoursesTable from '@/components/courses/CoursesTable';

const store = useStore();
const route = useRoute();
const router = useRouter();

store.dispatch('product/fetchProduct', route.params.id);
const product = computed(() => store.getters['product/getProduct']);

store.dispatch('course/fetchProductOwners');

if (!route.query.page) router.replace({ query: { ...route.query, page: 1 } });
else store.dispatch('courses/fetchCourses', { ...route.query, product_id: route.params.id });

watch(route, (value) => {
  if (value.query.page) {
    store.dispatch('courses/fetchCourses', { ...value.query, product_id: value.params.id });
  }
}, { deep: true });
</script>
