import { apiClient } from '../../custom_functions/api_client';

export default {
  namespaced: true,

  state: {
    recommendedCourses: [],
  },

  getters: {
    getRecommendedCourses(state) {
      return state.recommendedCourses;
    },
  },

  actions: {
    async fetchRecommendedCourses({ commit }) {
      const response = await apiClient.get('/v2/courses/recommended/dashboard_card');
      commit('updateCourses', response.data.courses);
    },
    async changeRecommendedCourses({ commit }, recommendedCourses) {
      const response = await apiClient.post(
        '/v2/courses/recommended/dashboard_card',
        { course_ids: recommendedCourses },
      );
      commit('updateCourses', response.data.courses);
    },
  },

  mutations: {
    updateCourses(state, courses) {
      state.recommendedCourses = courses;
    },
  },
};
