<template>
  <textarea :id="`${id}-${uniqCode}`"/>
</template>

<script>
import { apiClient } from '@/custom_functions/api_client';
import { contentUiCss, contentCss } from '@/custom_functions/tinymce.js';

import tinymce from 'tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/models/dom';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/image';

import Prism from 'prismjs';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-python';

export default {
  name: 'Editor',

  data() {
    return {
      uniqCode: Number(new Date()),
      contentStyle: contentUiCss + '\n' + contentCss,
      plugins:
        'advlist emoticons link lists table searchreplace visualblocks code fullscreen codesample insertdatetime media table emoticons wordcount image',
      codesampleLanguages: [
        { text: 'HTML/XML', value: 'markup' },
        { text: 'JavaScript', value: 'javascript' },
        { text: 'CSS', value: 'css' },
        { text: 'PHP', value: 'php' },
        { text: 'Ruby', value: 'ruby' },
        { text: 'Python', value: 'python' },
        { text: 'Java', value: 'java' },
        { text: 'C', value: 'c' },
        { text: 'C#', value: 'csharp' },
        { text: 'C++', value: 'cpp' },
        { text: 'Go', value: 'go' },
        { text: 'Python', value: 'python' },
      ],
      toolbar:
        'undo redo | styles | bold italic underline strikethrough | \
                forecolor backcolor | code codesample | \
                alignleft aligncenter alignright | \
                blockquote bullist numlist outdent indent | \
                image link media | \
                removeformat | fullscreen | custom',
    };
  },

  props: {
    modelValue: {
      type: String,
      requared: true,
    },

    id: {
      type: String,
      requared: true,
    },

    placeholder: {
      type: String,
      default: 'Введите текст здесь...',
    },

    height: {
      type: Number,
      default: 400,
    },
  },

  mounted() {
    tinymce.init({
      selector: `#${this.id}-${this.uniqCode}`,
      skin: false,
      promotion: false,
      content_css: false,
      branding: false,
      contextmenu: false,
      automatic_uploads: true,
      placeholder: this.placeholder,
      content_style: this.contentStyle,
      plugins: this.plugins,
      codesample_global_prismjs: true,
      codesample_languages: this.codesampleLanguages,
      toolbar: this.toolbar,
      height: this.height,
      video_template_callback: this.getVideoTemplate,
      setup: this.getSetupSettings,
      images_upload_handler: this.uploadFile,
    });
  },

  methods: {
    getVideoTemplate(data) {
      const hasToken = data.source.includes('?token=');
      return `<div><video class="video-player" width="300px" height="140px"><source src="${
        data.source
      }${!hasToken ? '?token=' : ''}" type="video/mp4" /></video></div>`;
    },

    getSetupSettings(editor) {
      editor.on('init', () => {
        editor.setContent(this.modelValue || '');
      });
      editor.on('input', () => {
        this.$emit('update:modelValue', editor.getContent());
      });
      editor.on('focusout', () => {
        this.$emit('update:modelValue', editor.getContent());
      });
    },

    async uploadFile(blobInfo, progress) {
      const formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());

      const response = await apiClient.post('v2/images', formData, {
        onUploadProgress(e) {
          progress((e.loaded / e.total) * 100);
        },
        skipErrorHandling: true,
      });

      const result = new Promise((resolve, reject) => {
        if (response.status >= 200 && response.status < 300) {
          const json = response.data;
          if (!json || typeof json.image.url != 'string') {
            return reject('Invalid JSON: ' + response);
          }
          return resolve(json.image.url);
        } else {
          if (response.status) return reject(`HTTP Error ${response.status}`);
          else return reject('HTTP Error');
        }
      });
      return result;
    },
  },
};
</script>
