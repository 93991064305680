<template>
  <tr v-show="!isCourseHidden" class="table-cell">
    <th scope="row" class="align-middle ps-3">{{ course.id }}</th>
    <td class="text-primary align-middle position-relative">
      <router-link class="stretched-link text-decoration-none" :to="`/courses/${course.id}/edit`">
        {{ course.name }}
      </router-link>
    </td>
    <td class="align-middle">{{ displayedTypeString }}</td>
    <td class="align-middle" :class="currentPublicationStatusClass">
      {{ publicationStatusString }}
    </td>
    <td class="align-middle">
      {{ dateFrom }}
      <br v-if="dateFrom && dateTo" />
      {{ dateTo }}
    </td>
    <td class="align-middle break-word">{{ productOwnerName }}</td>
    <td :class="xlCellClasses" class="align-middle text-end">{{ course.participations_count }}</td>
    <td :class="xlCellClasses" class="align-middle text-end">{{ course.units_count }}</td>
    <td :class="xlCellClasses" class="align-middle text-end">{{ course.lessons_count }}</td>
    <td :class="xlCellClasses" class="align-middle text-end">{{ course.steps_count }}</td>
    <td :class="xlCellClasses" class="align-middle text-end">
      {{ createdAt }}
      <br v-if="createdAt && updatedAt" />
      {{ updatedAt }}
    </td>
    <td class="align-middle text-end pe-3">
      <b-dropdown variant="link" toggle-class="text-decoration-none text-body" no-caret
                  right>
        <template #button-content>
          <font-awesome-icon icon="ellipsis" />
        </template>
        <b-dropdown-item :to="`/courses/${course.id}/edit`">
          <font-awesome-icon icon="pen-to-square" class="me-2" />
          Изменить
        </b-dropdown-item>
        <CourseCopyController v-slot="props" :course="course">
          <b-dropdown-item-button :disabled="httpProcess" @click="props?.action">
            <font-awesome-icon icon="copy" class="me-2" />
            Дублировать
          </b-dropdown-item-button>
        </CourseCopyController>
        <CourseArchiveController v-slot="props" :course="course">
          <b-dropdown-item-button :disabled="httpProcess" @click="props?.action">
            <font-awesome-icon :icon="props?.icon" class="me-2" />
            {{ props?.text }}
          </b-dropdown-item-button>
        </CourseArchiveController>
        <b-dropdown-divider />
        <b-dropdown-item-button :disabled="deleteButtonDisabled" @click="removeCourse">
          <font-awesome-icon icon="trash" class="me-2" />
          Удалить
        </b-dropdown-item-button>
      </b-dropdown>
    </td>
  </tr>
</template>

<script setup>
import { formatDisplayedType } from '@/custom_functions/course_types';
import { formatDate } from '@/custom_functions/custom_functions';

import { useProductOwners } from '@/composables/courses/use_product_owners';
import { usePublicationStatusFilter } from '@/composables/courses/use_publication_status_filter';

import { computed, toRefs } from 'vue';
import { useStore } from 'vuex';

import CourseArchiveController from '@/components/courses/CourseArchiveController';
import CourseCopyController from '@/components/courses/CourseCopyController';

const store = useStore();

const props = defineProps({ course: { type: Object, required: true } });
const { course } = toRefs(props);

const displayedTypeString = computed(() => formatDisplayedType(course.value.displayed_type));

const { getProductOwnerNameById } = useProductOwners();
const productOwnerName = computed(() => getProductOwnerNameById(course.value.po_user_id));

const { formatPublicationStatus, publicationStatusClass } = usePublicationStatusFilter();

const currentPublicationStatusClass = computed(() => {
  return publicationStatusClass(course.value.publication_status);
});

const publicationStatusString = computed(() => {
  return formatPublicationStatus(course.value.publication_status);
});

const dateFrom = computed(() => formatDate(course.value.date_from));
const dateTo = computed(() => formatDate(course.value.date_to));
const createdAt = computed(() => formatDate(course.value.created_at));
const updatedAt = computed(() => formatDate(course.value.updated_at));

const httpProcess = computed(() => store.getters['courses/getHttpProcess']);

const isCourseHidden = computed(() => {
  return store.getters['courses/getHiddenCourseIds'].includes(course.value.id);
});

async function removeCourse() {
  store.commit('courses/addIdToHiddenCourseIds', course.value.id);
  store.commit('toast/addRevertToast', {
    comment: `Тариф «${course.value.name}» удален`,
    buttonText: 'Восстановить тариф',
    action: () => store.dispatch('courses/deleteCourse', course.value.id),
    revert: () => store.commit('courses/removeIdFromHiddenCourseIds', course.value.id),
  });  
}

const xlCellClasses = ['d-none', 'd-xl-table-cell'];

const deleteButtonDisabled = computed(() => {
  return !!course.value.participations_count || httpProcess.value;
});
</script>

