import validateUniqueName from './helpers/validate_unique_name';

export default function validateDirection(value, payload = {}) {
  const result = {};
  if (value && payload.directions) {
    const name = validateUniqueName(value.name, { otherNames: payload.directions.filter((direction) => direction.id !== value.id) });
    if (name.length) result.name = name;
  } else {
    result.direction = [];
    if (!value) result.direction.push('Направление не может быть пустым');
    if (!payload.directions) result.direction.push('Не возможно проверить направление, пока не загружены остальные направления');

  }
  return result;
}
