<template>
  <div class="container">
    <h2 class="mb-4">Добавление промокодов</h2>

    <CampaignParams :campaign="campaign"/>

    <CampaignCourses
      :courses="courses"
      @updateCourses="update"
    />

    <!-- Promocodes -->
    <div class="mb-4">
      <h3 class="mb-3">Список промокодов</h3>

      <div class="row">
        <div class="col-8">
          <div class="mb-3">
            <label class="mb-2">Промокоды через пробел</label>

            <textarea
              v-model="promoCodes"
              class="form-control"
              rows="3"
            />
          </div>
        </div>
      </div>
    </div>

    <button
      type="submit"
      class="btn btn-primary"
      @click="createCampaign"
    >Сохранить</button>
  </div>
</template>

<script>
import CampaignParams from '@/components/promo_codes/CampaignParams';
import CampaignCourses from '@/components/promo_codes/CampaignCourses';

export default {
  name: 'PromoCodesNew',

  components: {
    CampaignParams,
    CampaignCourses,
  },

  data() {
    return {
      campaign: {
        unit: 'percents',
        courses_count_for_activation: 0,
      },
      courses: null,
      selectedCourses: [],
      promoCodes: '',
    };
  },

  created() {
    this.$store.dispatch('courses/fetchCoursesOmitPagination');
  },

  computed: {
    courses() {
      return this.$store.getters['courses/getCoursesOmitPagination'];
    },
  },

  methods: {
    async createCampaign() {
      if (!this.campaign.name || !this.campaign.discount) {
        return this.$root.$bvToast.toast('Пожалуйста, заполните название и скидку для кампании', {
          title: 'Уведомление',
          variant: 'danger',
          autoHideDelay: 3000,
        });
      }

      if (this.campaign.unit === 'rubles') {
        if (this.campaign.discount <= 0) {
          return this.$root.$bvToast.toast('Размер скидки должен быть больше 0₽', {
            title: 'Уведомление',
            variant: 'danger',
            autoHideDelay: 3000,
          });
        }
      }
      else {
        if (this.campaign.discount <= 0 || this.campaign.discount > 100) {
          return this.$root.$bvToast.toast('Размер скидки должен быть в диапозоне от 1% до 100%', {
            title: 'Уведомление',
            variant: 'danger',
            autoHideDelay: 3000,
          });
        }
      }

      let campaign = this.campaign;
      campaign.course_ids = this.selectedCourses;
      campaign.promo_codes_list = this.promoCodes.split(' ');

      let postData = {
        campaign,
      };

      await this.$apiClient.post('/v2/campaigns', postData);

      await this.$router.replace({ name: 'promo_codes_index', params: { page: 1 } });

      this.$root.$bvToast.toast('Кампания сохранена', {
        title: 'Уведомление',
        variant: 'primary',
        autoHideDelay: 3000,
      });
    },

    update(courses) {
      this.selectedCourses = courses;
    },
  },
};
</script>
