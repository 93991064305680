<template>
  <div class="d-flex flex-column align-items-center">
    <b-avatar
      size="60px"
      :src="speakerHasPhoto ? speaker.photo.preview_url : ''"
      :variant="speakerHasPhoto ? 'light' : 'primary'"
      class="mb-1"
    />
    <span v-if="showFileName && photoDataUri" class="speaker-photo-name">
      Фото<br/>{{ speaker.photo.name }}<br/>загружено
    </span>
    <input
      :id="`img-${speaker.id}`"
      type="file"
      class="d-none"
      accept="image/jpeg, image/png"
      @change="changePhoto($event)"
    />
    <label class="text-primary small btn btn-sm btn-link" :for="`img-${speaker.id}`">
      изменить
    </label>
  </div>
</template>

<script>
import { makeToast } from '@/custom_functions/custom_functions.js';

export default {
  name: 'SpeakerAvatar',

  props: {
    speaker: Object,
    showFileName: Boolean,
    modelValue: null,
  },

  data() {
    return {
      photoDataUri: null,
    };
  },

  computed: {
    speakerHasPhoto() {
      if (this.speaker.photo) return this.speaker.photo.preview_url;
      return false;
    },
  },

  created() {
    this.photoDataUri = this.value; 
  },

  methods: {
    changePhoto(event) {
      if (event.target.files.length > 0) {
        let file = event.target.files[0];
        let fileType = file.type;
        let allowedFormats = ['image/jpeg', 'image/png'];
        if (!allowedFormats.includes(fileType)) makeToast('Формат файла не поддерживается', 'danger');
        let reader = new FileReader();
        reader.onloadend = () => {
          this.photoDataUri = reader.result;
          this.speaker.photo = file;
          this.$emit('update:modelValue', this.photoDataUri);
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style>
  .speaker-photo-name {
    font-size: 12px;
    text-align: center;
    max-width: 60px;
    overflow-x: hidden;
  }
</style>
