<template>
  <table class="table table-sm table-hover">
    <thead>
      <tr>
        <th
          v-for="title, index in tableTitles"
          :key="index"
          :class="thClasses(index, title.hideOnMobile)"
          class="py-2 align-middle"
          scope="col"
        >
          <template v-if="title.type === 'text'">{{ title.value }}</template>
          <TableFilter
            v-else-if="title.type === 'filter' && title.value === 'displayed_type'"
            v-model:model-value="displayedType"
            :values="displayedTypes"
            :format-function="formatDisplayedType"
            title="Тип"
            :disabled="httpProcess"
          />
          <TableFilter
            v-else-if="title.type === 'filter' && title.value === 'publication_status'"
            v-model:model-value="publicationStatus"
            :values="publicationStatuses"
            :format-function="formatPublicationStatus"
            title="Статус"
            :disabled="httpProcess"
          />
          <span v-else-if="title.type === 'html'" v-html="title.value" />
        </th>
      </tr>
    </thead>
    <tbody v-if="courses?.length && productOwners">
      <CoursesTableRow v-for="course of courses" :key="course.id" :course="course"/>
    </tbody>
  </table>
  <div v-if="courses && !courses.length" class="loader text-center fs-3 text-secondary">
    Тарифы не найдены
  </div>
  <div v-else-if="!courses || !productOwners" class="loader text-center">
    <b-spinner />
  </div>
  <Pagination
    v-if="courses"
    :total-pages="totalPages"
    :items-count="courses.length"
    :disabled="httpProcess"
  />
</template>

<script setup>
import CoursesTableRow from '@/components/courses/CoursesTableRow';
import Pagination from '@/components/Pagination';
import TableFilter from '@/components/TableFilter';

import { useDisplayedTypeFilter } from '@/composables/courses/use_displayed_type_filter';
import { useProductOwners } from '@/composables/courses/use_product_owners';
import { usePublicationStatusFilter } from '@/composables/courses/use_publication_status_filter';

import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const xlCellClasses = ['d-none', 'd-xl-table-cell'];

function thClasses(index, hideOnMobile) {
  const classes = [`table-col-${index + 1}`];
  if (!index) classes.push('ps-3');
  if (index === tableTitles.length - 1) classes.push('pe-3');
  if (hideOnMobile) classes.push(...xlCellClasses);
  return classes;
}

const tableTitles = [
  { type: 'text', value: 'ID' },
  { type: 'text', value: 'Тариф' },
  { type: 'filter', value: 'displayed_type' },
  { type: 'filter', value: 'publication_status' },
  { type: 'html', value: 'Начало<br/>Окончание' },
  { type: 'html', value: '<span class="text-nowrap">Директор</span>' },
  { type: 'text', value: 'Студенты', hideOnMobile: true },
  { type: 'text', value: 'Модули', hideOnMobile: true },
  { type: 'text', value: 'Уроки', hideOnMobile: true },
  { type: 'text', value: 'Шаги', hideOnMobile: true },
  { type: 'html', value: 'Создан<br/>Изменен', hideOnMobile: true },
  { type: 'text', value: '' },
];

const {
  displayedTypes,
  displayedType,
  formatDisplayedType,
} = useDisplayedTypeFilter('courses');

const {
  publicationStatuses,
  publicationStatus,
  formatPublicationStatus,
} = usePublicationStatusFilter();

const courses = computed(() => store.getters['courses/getCourses']);

const totalPages = computed(() => store.getters['courses/getCoursesTotalPages']);

const httpProcess = computed(() => store.getters['courses/getHttpProcess']);

const { productOwners } = useProductOwners();
</script>

<style scoped>
.table-col-1,
.table-col-10 {
  width: 55px;
}
.table-col-3 {
  width: 151px;
}
.table-col-4 {
  width: 145px;
  width: 145px;
}
.table-col-5 {
  width: 100px;
}
.table-col-6 {
  width: 125px;
}
.table-col-7 {
  width: 85px;
}
.table-col-8 {
  width: 75px;
}
.table-col-9 {
  width: 60px;
}
.table-col-11 {
  width: 95px;
}
.table-col-12 {
  width: 65px;
}
.table-cell {
  height: 62px;
}
</style>

