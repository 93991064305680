<template>
  <div class="d-flex justify-content-between align-items-start">
    <div class="d-flex align-items-center flex-grow-1">
      <router-link
        :to="router.options.history.state.back || `/courses?tab=${coursesItemType}s`"
        class="h3 text-dark me-3 mb-0"
      >
        <font-awesome-icon icon="xmark" />
      </router-link>
      <h1 class="h3 mb-0 text-break">{{ title }}</h1>
    </div>
    <!-- TODO: Merge new-type, new-display-type, new-type-name into convert-to -->
    <ConversionButton
      v-if="coursesItem.course_type === 'slurm' && !isNew"
      :course="coursesItem"
      new-type="video"
      new-displayed-type="slurm_record"
      new-type-name="запись интенсива"
      class="me-3"
      @courseConverted="$store.commit('course/updateCourse', $event)"
    />
    <ConversionButton
      v-if="['stepik', 'video'].includes(coursesItem.course_type) && !isNew"
      :course="coursesItem"
      new-type="demo"
      new-displayed-type="demo_course"
      new-type-name="демо-курс"
      class="me-3"
      @courseConverted="$store.commit('course/updateCourse', $event)"
    />
    <CourseCopyController
      v-if="coursesItemType === 'course' && !isNew"
      v-slot="props"
      :course="coursesItem"
    >
      <button class="btn btn-outline-secondary me-3" @click="props?.action">Дублировать</button>
    </CourseCopyController>
    <CustomSelect
      id="publication-status"
      v-model:model-value="coursesItem.publication_status"
      :options="statuses"
      class="me-3 publication-status"
    />
    <button class="btn btn-primary text-nowrap" :disabled="httpProcess" @click="emit('save')">
      <span v-if="httpProcess" class="spinner-border spinner-border-sm"/>
      Сохранить
    </button>
  </div>
</template>

<script setup>
import CustomSelect from '@/components/CustomSelect';
import ConversionButton from '@/components/courses/ConversionButton';
import CourseCopyController from '@/components/courses/CourseCopyController';

import { getPublicationStatusesForSelect } from '@/custom_functions/publication_statuses';

import { computed, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();

const router = useRouter();

const props = defineProps({
  isNew: { type: Boolean, default: false },
  coursesItemType: {
    type: String,
    required: true,
    validator: (value) => ['product', 'course', 'container'].includes(value),
  },
});
const { isNew, coursesItemType } = toRefs(props);

const coursesItem = computed(() => {
  if (coursesItemType.value === 'course') return store.getters['course/getCourse'];
  if (coursesItemType.value === 'container') return store.getters['container/getContainer'];
  if (coursesItemType.value === 'product') return store.getters['product/getProduct'];
  return null;
});

const httpProcess = computed(() => {
  if (coursesItemType.value) return store.getters[`${coursesItemType.value}/getHttpProcess`];
  return null;
});

const emit = defineEmits(['save']);

const statuses = getPublicationStatusesForSelect();

const title = computed(() => {
  if (isNew.value) {
    if (coursesItemType.value === 'course') return 'Новый тариф';
    if (coursesItemType.value === 'container') return 'Новый комлект/подписка';
    return 'Новый курс';
  } else {
    if (coursesItemType.value ==='product') {
      return `${coursesItem.value.id}. ${coursesItem.value.name}`;
    }
    if (coursesItemType.value ==='course') {
      return `${coursesItem.value.id}. Тариф ${coursesItem.value.name}`;
    }
    return coursesItem.value.name;
  }
});
</script>

<style scoped>
.publication-status {
  min-width: 150px;
}
</style>

