<template>
  <div class="row">
    <div class="col-6">
      <form class="input-group" @submit.prevent="settings.search = search">
        <input
          v-model="search"
          class="form-control"
          placeholder="Поиск по номеру решения, логину, ФИО студента"
          type="search"
          :class="noAttempts && !this.settings.search && 'bg-white'"
          :disabled="noAttempts && !this.settings.search"
        />
        <div>
          <button
            class="btn btn-primary"
            :disabled="noAttempts && !this.settings.search"
            :class="noAttempts && !this.settings.search && 'btn-secondary'"
          >
            Поиск
          </button>
        </div>
      </form>
    </div>
    <div class="col-3">
      <button class="form-control" @click="settings.sort = settings.sort === 'first-old' ? 'first-new' : 'first-old'">
        <font-awesome-icon :icon="settings.sort === 'first-old' ? 'sort-amount-up' : 'sort-amount-down-alt'" />
        Сначала {{ settings.sort === 'first-old' ? 'новые' : 'старые' }}
      </button>
    </div>
    <div class="col-3">
      <select v-model="settings.filter" class="form-control">
        <option value="all">Все</option>
        <option value="awaiting">Ожидает проверки</option>
        <option value="revision">Нужна доработка</option>
        <option value="success">Решение зачтено</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewerAnswersSetting',

  props: { settings: Object, noAttempts: Boolean },

  data() {
    return {
      search: null,
    };
  },
};
</script>

<style>

</style>