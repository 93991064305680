<template>
  <CustomSelect
    id="course-tags"
    v-model:model-value="course.tag_list"
    :options="tagsOptions"
    label="Теги (для отображения рекомендуемых курсов на главной)"
    class="mb-3"
    multiple
  />
  <div class="mb-3">
    <label class="mb-2">Script start</label>
    <input v-model.lazy.trim="course.script_start" type="text" class="form-control" />
    <small class="form-text text-muted">
      Скрипт, который запускается за N часов до начала подготовки к интенсиву или вечерней школе,
      в остальных случаях при записи студента на курс
    </small>
  </div>
  <div class="mb-3">
    <label class="mb-2">Script stop</label>
    <input v-model.lazy.trim="course.script_stop" type="text" class="form-control" />
    <small class="form-text text-muted">
      Скрипт, который вызывается при удалении студента из курса
    </small>
  </div>
  <div class="mb-3 form-check form-switch">
    <input v-model="course.engineering_chat_available" type="checkbox" class="form-check-input"/>
    <label class="form-check-label">
      Доступ участников к клубу инженеров
    </label>
  </div>
  <div class="mb-3 form-check form-switch">
    <input v-model="course.with_certificate" type="checkbox" class="form-check-input"/>
    <label class="form-check-label">
      Выдаётся сертификат/свидетельство
    </label>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

import { useCustomSelectOptions } from '@/composables/use_custom_select_options';

import CustomSelect from '@/components/CustomSelect';

const store = useStore();

const course = computed(() => store.getters['course/getCourse']);

const tags = computed(() => store.getters['tags/getTags']);
const tagsOptions = computed(() => useCustomSelectOptions(tags.value));
</script>
