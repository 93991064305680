import ReviewersIndex from '@/views/reviewers/Index.vue';
import ReviewersShow from '@/views/reviewers/Show.vue';
import ReviewerAnswers from '@/views/reviewers/Answers.vue';
import ReviewerAnswer from '@/views/reviewers/Answer.vue';

export default [
  {
    path: '/reviewers',
    name: 'reviewers_index',
    component: ReviewersIndex,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/reviewers/:reviewerId',
    name: 'reviewers_show',
    component: ReviewersShow,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/reviewers/:reviewerId/steps/:stepId',
    name: 'reviewer_answers',
    component: ReviewerAnswers,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },

  {
    path: '/reviewers/:reviewerId/steps/:stepId/answers/:answerId',
    name: 'reviewer_answer',
    component: ReviewerAnswer,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
];