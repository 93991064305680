<template>
  <div>
    <div class="mb-3">
      <label for="step-text" class="mb-2">Шаг: {{ formatStepType(step.type) }}</label>
      <div>
        <Editor
          id="step-text"
          v-model:model-value="step.text"
          :height="editorHeight"
          placeholder="Новый шаг"
        />
      </div>
    </div>

    <div class="mb-3">
      <input v-model.lazy="step.duration" type="number" class="form-control w-25"
             placeholder="Длительность в минутах" />
    </div>

    <StepAttemptsLimit v-if="hasAttemptsLimit" v-model:model-value="step.attempts_limit" class="mb-3" />

    <component :is="dataComponent" v-if="dataComponent" :step="step" />
  </div>
</template>

<script>
import { formatStepType } from '@/custom_functions/custom_functions';
import StepAttemptsLimit from '@/components/lessons/StepAttemptsLimit.vue';
import TextTaskStep from '@/components/lessons/TextTaskStep.vue';
import InteractStep from '@/components/lessons/InteractStep.vue';
import QuizStep from '@/components/lessons/QuizStep.vue';
import SortStep from '@/components/lessons/SortStep.vue';
import MatchStep from '@/components/lessons/MatchStep.vue';

export default {
  name: 'StepContent',

  components: { StepAttemptsLimit, TextTaskStep, InteractStep, QuizStep, SortStep, MatchStep },

  props: { step: { type: Object, required: true } },

  computed: {
    editorHeight() {
      if (this.step.type === 'TextStep' || this.step.type === 'VideoStep' || this.step.type === 'DeferredStep') return 500;
      else return 400;
    },

    hasAttemptsLimit() {
      return this.step.type !== 'TextStep' && this.step.type !== 'VideoStep';
    },

    dataComponent() {
      if (
        this.step.type === 'TextTaskStep'
        || this.step.type === 'InteractStep'
        || this.step.type === 'QuizStep'
        || this.step.type === 'SortStep'
        || this.step.type === 'MatchStep'
      ) return this.step.type;
      return null;
    },
  },

  methods: { formatStepType },
};
</script>
