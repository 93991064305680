<template>
  <div class="container">
    <h2>Рекомендованные курсы</h2>
    <MyCoursesRecommendations />
    <h2 class="mb-3">
      Теги
      <Instruction block="main"/>
    </h2>
    <div v-if="tags">
      <v-select
        v-model:model-value="currentTags"
        taggable
        multiple
        :errors="errors.tags"
        placeholder="Добавьте теги"
        class="mb-3"
      />
      <button class="btn btn-primary mb-5" @click="saveTags()">Сохранить теги</button>
    </div>
    <div v-else>
      <b-spinner/>
    </div>
    <h2 class="mb-4">Приоритет тегов</h2>
    <PolicyPriority v-if="tags && policy" :tags="tags" :policy="policy" />
    <div v-else>
      <b-spinner/>
    </div>
    <CorpRecommended/>
  </div>
</template>

<script>
import CorpRecommended from '../../components/CorpRecommended';
import Instruction from '../../components/Instruction';
import MyCoursesRecommendations from '@/components/MyCoursesRecommendations.vue';
import PolicyPriority from '@/components/tags/PolicyPriority.vue';
import { makeToast } from '@/custom_functions/custom_functions';

export default {
  name: 'TagsEdit',

  components: {
    CorpRecommended,
    Instruction,
    MyCoursesRecommendations,
    PolicyPriority,
  },

  data() {
    return {
      currentTags: [],
      errors: {
        tags: [],
      },
    };
  },

  computed: {
    tags() {
      return this.$store.getters['tags/getTags'];
    },

    policy() {
      return this.$store.getters['tags/getPolicy'];
    },
  },

  created() {
    this.$store.dispatch('tags/fetchTagsSettings');
    this.currentTags = this.tags;
  },

  methods: {
    saveTags() {
      this.$store.dispatch('tags/changeTagsSettings', { tags: this.currentTags, policy: this.policy } );
      makeToast('Теги сохранены', 'success');
    },
  },

  watch: {
    tags(value) {
      this.currentTags = value;
    },
  },
};
</script>
