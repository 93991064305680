<template>
  <div v-if="product && directions && productOwners && products && courses" class="container">
    <CoursesItemHeader
      class="mb-4"
      courses-item-type="product"
      @save="editProduct"
    />
    <ProductTabs />
    <ConflictModal
      v-if="conflict"
      v-model:model-value="shownConflictModal"
      :my-changes="conflict.my_changes"
      :their-changes="conflict.their_changes"
    />
  </div>
  <div v-else class="loader text-center">
    <b-spinner />
  </div>
</template>

<script setup>
import CoursesItemHeader from '@/components/courses/CoursesItemHeader';
import ProductTabs from '@/components/courses/products/ProductTabs';
import ConflictModal from '@/components/conflicts/ConflictModal';

import { computed, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { makeToast } from '@/custom_functions/custom_functions';

const store = useStore();
const route = useRoute();

store.dispatch('product/fetchProduct', route.params.id);
const product = computed(() => store.getters['product/getProduct']);

store.dispatch('directions/fetchDirections');
const directions = computed(() => store.getters['directions/getDirections']);

store.dispatch('course/fetchProductOwners');
const productOwners = computed(() => store.getters['course/getProductOwners']);

store.dispatch('products/fetchProducts');
const products = computed(() => store.getters['products/getProducts']);

const hasErrors = computed(() => store.getters['product/getHasErrors']);

watch(product, () => {
  if (hasErrors.value) store.dispatch('product/validateProduct');
}, { deep: true });

const conflict = computed(() => store.getters['product/getConflict']);

const shownConflictModal = ref(false);

watch(conflict, (value) => {
  if (value) shownConflictModal.value = true;
});

async function editProduct() {
  await store.dispatch('product/saveProduct');
  if (!hasErrors.value && !conflict.value) makeToast('Курс сохранен', 'success');
}

store.dispatch('courses/fetchCoursesOmitPagination');
const courses = computed(() => store.getters['courses/getCoursesOmitPagination']);
</script>
