import { apiClient } from '@/custom_functions/api_client';

export default {
  namespaced: true,

  state: { tags: null, policy: null },

  getters: {
    getTags: (state) => state.tags,
    getPolicy: (state) => state.policy,
  },

  actions: {
    async fetchTagsSettings({ commit }, rights) {
      const response = await apiClient.get('/v2/tags', { skipErrorHandling: rights });
      commit('updateTags', response.data.tags.sort());
      commit('updatePolicy', response.data.policy);
    },

    async changeTagsSettings({ commit }, { tags, policy }) {
      await apiClient.post('/v2/tags', { tags: tags.map((tag) => tag), priority: policy });
      commit('updateTags', tags);
      commit('updatePolicy', policy);
    },
  },

  mutations: {
    updateTags(state, tags) {
      state.tags = tags;
    },

    updatePolicy(state, policy) {
      state.policy = policy;
    },
  },
};
