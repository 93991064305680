import { apiClient } from '../../custom_functions/api_client';

export default {
  namespaced: true,

  state: {
    usersNumber: null,
    kinds: null,
    recipientsCount: null,
  },

  getters: {
    getUsersNumber(state) {
      return state.usersNumber;
    },

    getKinds(state) {
      return state.kinds;
    },

    getRecipientsCount(state) {
      return state.recipientsCount;
    },
  },

  actions: {
    async fetchMeta({ commit }, payload) {
      commit('setRecipientsCount', null);
      let response;
      if (payload) {
        let params = { only_to_not_signed_in: payload.onlyToNotSignedIn };
        const coursesIds = payload.coursesIds || [];
        const usersIds = payload.usersIds || [];
        if (coursesIds.length) params.courses_ids = coursesIds;
        else if (usersIds.length) params.users_ids = usersIds;
        response = await apiClient.get('/v2/mass_deliveries/meta', { params });
      }
      else response = await apiClient.get('/v2/mass_deliveries/meta');
      commit('setUsersNumber', response.data.users);
      commit('setKinds', response.data.kinds);
      commit('setRecipientsCount', response.data.will_receive);
    },
  },

  mutations: {
    setUsersNumber(state, usersNumber) {
      state.usersNumber = usersNumber;
    },

    setKinds(state, kinds) {
      state.kinds = kinds;
    },

    setRecipientsCount(state, recipientsCount) {
      state.recipientsCount = recipientsCount;
    },
  },
};
