<template>
  <div v-if="product && directions && productOwners && products && courses" class="container">
    <CoursesItemHeader
      courses-item-type="product"
      is-new
      class="mb-4"
      @save="saveProduct"
    />
    <ProductTabs />
  </div>
  <div v-else class="loader text-center">
    <b-spinner />
  </div>
</template>

<script setup>
import CoursesItemHeader from '@/components/courses/CoursesItemHeader';
import ProductTabs from '@/components/courses/products/ProductTabs';

import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { makeToast } from '@/custom_functions/custom_functions';

const store = useStore();

const router = useRouter();

store.dispatch('product/createProduct');
const product = computed(() => store.getters['product/getProduct']);

store.dispatch('directions/fetchDirections');
const directions = computed(() => store.getters['directions/getDirections']);

store.dispatch('course/fetchProductOwners');
const productOwners = computed(() => store.getters['course/getProductOwners']);

store.dispatch('products/fetchProducts');
const products = computed(() => store.getters['products/getProducts']);

const hasErrors = computed(() => store.getters['product/getHasErrors']);

watch(product, () => {
  if (hasErrors.value) store.dispatch('product/validateProduct');
}, { deep: true });

async function saveProduct() {
  await store.dispatch('product/saveNewProduct');
  if (!hasErrors.value) {
    makeToast('Курс создан', 'success');
    router.replace(`/courses/products/${product.value.id}/edit`);
  }
}

store.dispatch('courses/fetchCoursesOmitPagination');
const courses = computed(() => store.getters['courses/getCoursesOmitPagination']);
</script>
