<template>
  <div>
    <div class="mb-3">
      <label>URL в Gitlab</label>
      <input v-model.lazy="course.gitlab_url" type="text" class="form-control" />
    </div>
    <div class="d-flex align-items-start mb-3">
      <div class="flex-fill me-3">
        <div class="input-group">
          <input
            v-model.lazy="resource.url"
            type="text"
            class="form-control"
            required
            :class="error && 'is-invalid'"
          />
          <button class="btn btn-primary" @click="addResource">Добавить</button>
        </div>
        <small v-if="error" class="form-text text-danger" >{{ error }}</small>
      </div>

      <div class="me-3">
        <b-button variant="primary" @click="setRights" >Назначить права студентам</b-button>
      </div>

      <div v-if="unlinked_gitlab > 0">
        <b-button variant="primary" class="btn-warning" @click="syncAccounts">
          Связать учетки студентов с Gitlab:
          <span class="badge text-bg-danger">{{ unlinked_gitlab }}</span>
        </b-button>
      </div>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th>Название ресурса</th>
          <th width="20">Тип</th>
          <th width="1">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="resource in resources" :key="resource.id">
          <td>
            <a v-bind:href="resource.url">{{ resource.url }}</a>
          </td>
          <td>{{ resource.resource_type }}</td>
          <td>
            <button class="btn btn-outline-danger btn-sm" @click="deleteResource(resource.id)">
              <font-awesome-icon icon="trash" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { apiClient } from '@/custom_functions/api_client';
import { makeToast } from '@/custom_functions/custom_functions';

import { useStore } from 'vuex';
import { computed, ref } from 'vue';

const store = useStore();

const course = computed(() => store.getters['course/getCourse']);

const resources = ref([]);
const resource = ref({ url: '' });
const unlinked_gitlab = ref(0);
const error = ref(null);

loadResources();

async function loadResources() {
  error.value = null;
  resource.value.url = '';
  const response = await apiClient.get(`v2/courses/${course.value.id}/gitlab/resources`);
  resources.value = response.data.resources;
  unlinked_gitlab.value = response.data.meta.unlinked_gitlab;
}

async function setRights() {
  await apiClient.patch(`v2/courses/${course.value.id}/gitlab/resources/reassign`);
  makeToast('Права назначаются', 'success');
}

async function syncAccounts() {
  await apiClient.patch(`v2/courses/${course.value.id}/gitlab/resources/sync_accounts`);
  makeToast('Учётки привязываются', 'success');
}

async function deleteResource(resourceId) {
  await apiClient.delete(`v2/courses/${course.value.id}/gitlab/resources/${resourceId}`);

  makeToast('Ресурс удалён', 'success');

  await loadResources();
}

async function addResource() {
  if (!resource.value.url) {
    error.value = 'Некорректный URL';
    return;
  }

  resource.value.url = resource.value.url.trim();

  try {
    await apiClient.post(`v2/courses/${course.value.id}/gitlab/resources`, {
      resource: resource.value,
    });

    makeToast('Ресурс добавлен', 'success');

    await loadResources();
  } catch (e) {
    const {status} = e.response;

    if (status == 422) {
      error.value = `Ошибки: ${e.response.data.error.url}`;
    }
  }
}
</script>
