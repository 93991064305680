import { apiClient } from '@/custom_functions/api_client';
import { validateDirection } from '@/custom_functions/validation_functions';

export default {
  namespaced: true,

  state: { directions: null, currentDirections: null, errors: {} },

  getters: {
    getCurrentDirections: (state) => state.currentDirections,

    getDirections: (state) => state.currentDirections,

    getHasChanges: (state) => {
      if (state.directions && state.currentDirections) {
        return (
          state.currentDirections.some(
            (newDirection) =>
              !state.directions.find((oldDirection) => oldDirection.id === newDirection.id),
          ) ||
          state.directions.some((oldDirection, index) => {
            const newDirectionIndex = state.currentDirections.findIndex(
              (direction) => direction.id === oldDirection.id,
            );
            return (
              newDirectionIndex === -1 ||
              index !== newDirectionIndex ||
              Object.keys(oldDirection).some(
                (key) => oldDirection[key] !== state.currentDirections[newDirectionIndex][key],
              )
            );
          })
        );
      }
      return false;
    },

    getDirectionErrors: (state) => (id) => {
      if (id) return state.errors[`${id}`] || {};
      else return {};
    },

    getHasErrors: (state) => !!Object.keys(state.errors).length,
  },

  actions: {
    async fetchDirections({ commit }) {
      const response = await apiClient.get('/v2/directions', {
        params: { sorting_keys: ['display_order', 'asc'] },
      });
      commit('updateDirections', response.data.directions);
      commit('updateCurrentDirections', response.data.directions);
    },

    async createDirection(_, direction) {
      await apiClient.post('/v2/directions', { direction: direction });
    },

    async editDirection(_, direction) {
      await apiClient.patch(`/v2/directions/${direction.id}`, {
        direction: direction,
      });
    },

    async deleteDirection(_, id) {
      await apiClient.delete(`/v2/directions/${id}`);
    },

    async editDirections({ state, dispatch, commit }) {
      if (state.directions && state.currentDirections) {
        const promises = [];
        state.directions.forEach((oldDirection) => {
          if (state.currentDirections.every((direction) => direction.id !== oldDirection.id)) {
            promises.push(dispatch('deleteDirection', oldDirection.id));
          }
        });

        state.currentDirections.forEach((currentDirection, index) => {
          const oldDirection = state.directions.find(
            (direction) => direction.id === currentDirection.id,
          );

          const hasChanges =
            oldDirection &&
            (Object.keys(currentDirection).some(
              (key) => currentDirection[key] !== oldDirection[key],
            ) ||
              currentDirection.display_order !== index);

          if (hasChanges) {
            promises.push(
              dispatch(
                'editDirection',
                Object.assign({}, currentDirection, { display_order: index }),
              ),
            );
          } else if (!oldDirection) {
            promises.push(
              dispatch(
                'createDirection',
                Object.assign({}, currentDirection, { display_order: index }),
              ),
            );
          }
        });

        await Promise.all(promises);

        commit('updateDirections', state.currentDirections);
      }
    },

    cancelCurrentDirectionsChanges({ commit, state }) {
      commit('updateCurrentDirections', state.directions);
    },

    validateDirection({ commit, state }, direction) {
      commit('updateDirectionErrors', {
        id: direction.id || 'new',
        errors: validateDirection(direction, {
          directions: state.currentDirections,
        }),
      });
    },

    validateAllDirections({ dispatch, state }) {
      if (state.currentDirections)
        state.currentDirections.forEach((direction) => dispatch('validateDirection', direction));
    },

    changeCurrentDirections({ commit }, currentDirection) {
      commit('updateCurrentDirections', currentDirection);
    },
  },

  mutations: {
    updateDirections(state, directions) {
      state.directions = directions.map((direction) => Object.assign({}, direction));
    },

    updateCurrentDirections(state, directions) {
      state.currentDirections = directions.map((direction) => Object.assign({}, direction));
    },

    addCurrentDirection(state, direction) {
      if (state.currentDirections) {
        const id = state.currentDirections.length
          ? Math.max.apply(
            null,
            state.currentDirections.map((direction) => direction.id || 0),
          ) + 1
          : 0;
        state.currentDirections.push(Object.assign({}, direction, { id }));
      } else state.currentDirections = [Object.assign({}, direction, { id: 0 })];
    },

    changeCurrentDirection(state, direction) {
      if (state.currentDirections) {
        const oldDirection = state.currentDirections.find(
          (currentDirection) => currentDirection.id === direction.id,
        );
        if (oldDirection)
          Object.keys(oldDirection).forEach((key) => (oldDirection[key] = direction[key]));
      }
    },

    removeCurrentDirection(state, id) {
      if (state.currentDirections) {
        const directionIndex = state.currentDirections.findIndex(
          (currentDirection) => currentDirection.id === id,
        );
        if (directionIndex >= 0) {
          state.currentDirections.splice(directionIndex, 1);
          delete state.errors[id];
        }
      }
    },

    updateDirectionErrors(state, payload) {
      const { errors } = state;
      if (Object.keys(payload.errors).length) {
        errors[`${payload.id}`] = payload.errors;
      } else delete errors[`${payload.id}`];
      state.errors = Object.assign({}, errors);
    },
  },
};
