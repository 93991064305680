<template>
  <form @submit.prevent>
    <div class="row">
      <CustomSelect
        v-if="shownPreorderInput"
        id="course-preorder"
        v-model:model-value="course.preorder"
        :options="preorderOptions"
        class="col-6 mb-3"
        label="Тип продажи*"
      />
    </div>
    <div class="row">
      <transition mode="in-out" name="fade">
        <div v-if="course.preorder" class="mb-3 col-6">
          <label for="course-prerelease-text" class="mb-2">Предварительная дата релиза</label>
          <input
            id="course-prerelease-text"
            v-model.lazy="course.prerelease_text"
            class="form-control"
            placeholder="Например, 1 января"
            maxlength="25"
          />
          <small class="text-muted">до 25 символов</small>
        </div>
      </transition>
    </div>
    <div class="row">
      <NumberInput
        id="course-price"
        v-model:model-value="course.price"
        :errors="errors.price"
        :disabled="course.course_type === 'demo'"
        label="Цена, ₽"
        class="col-6"
        required
      />
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-check form-switch pb-4">
          <input
            id="course-can-use-installment"
            v-model="course.can_use_installment"
            type="checkbox"
            class="form-check-input"
          />
          <label for="course-can-use-installment" class="form-check-label">
            Доступна рассрочка на 4 месяца
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <transition mode="in-out" name="fade">
        <SeasonPrices
          v-if="course.preorder"
          v-model:model-value="course.season_prices_attributes"
          :initialPrices="course.season_prices"
          :courseId="course.id"
          class="col-12"
        />
      </transition>
    </div>
  </form>
</template>

<script setup>
import SeasonPrices from '@/components/courses/SeasonPrices';
import CustomSelect from '@/components/CustomSelect';
import NumberInput from '@/components/NumberInput';

import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  courseItemType: {
    type: String,
    default: 'course',
    validator: (value) => ['course', 'container'].includes(value),
  },
});

const course = computed(() => {
  return store.getters[
    props.courseItemType === 'course'
      ? 'course/getCourse'
      : 'container/getContainer'
  ];
});

const errors = computed(() => {
  return store.getters[
    props.courseItemType === 'course'
      ? 'course/getErrors'
      : 'container/getErrors'
  ];
});

const preorderOptions = [{ value: false, name: 'Продажа' }, { value: true, name: 'Предпродажа' }];

const shownPreorderInput = computed(() => {
  return props.courseItemType === 'course';
});
</script>
