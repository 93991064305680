<template>
  <div class="container">
    <h2 class="mb-4">Новая компания</h2>

    <form @submit.prevent="createCompany">
      <div class="mb-3">
        <label class="mb-2">Название</label>

        <input v-model="company.name" type="text" class="form-control" />
      </div>

      <button type="submit" class="btn btn-outline-primary">Сохранить</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'companies_new', // eslint-disable-line vue/component-definition-name-casing

  data() {
    return {
      company: {},
    };
  },

  methods: {
    async createCompany() {
      let data = {
        company: this.company,
      };

      await this.$apiClient.post('/v2/companies', data);

      await this.$router.replace('/companies');

      this.$root.$bvToast.toast('Компания сохранена', {
        title: 'Уведомление',
        variant: 'primary',
        autoHideDelay: 3000,
      });
    },
  },
};
</script>
