<template>
  <slot :icon="icon" :text="buttonText" :action="toggleArchived">
    <button class="btn btn-primary">
      <font-awesome-icon :icon="icon" class="me-2" @click="toggleArchived" />
      {{ buttonText }}
    </button>
  </slot>
  <b-modal
    v-model="showArchiveModal"
    title="Убрать тариф в архив"
    header-class="align-items-baseline border-bottom-0"
    body-class="mb-2"
    hide-footer
  >
    <div class="fs-5">
      <p>
        Тариф пропадет из каталога, но останется в доступе для тех, кто его купил.
        Также скроем тариф в таблице в админке, из CRM и остальных мест.
        Чтобы найти архивный тариф, выберите в таблице фильтр по статусу “В архиве”.
      </p>
      <template v-if="bundles?.length">
        <span>Комплекты с этим тарифом:</span>
        <ul class="list-unstyled">
          <li v-for="bundle in bundles" :key="bundle.id">{{ bundle.name }}</li>
        </ul>
      </template>
      <div v-else-if="!bundles" class="text-center">
        <b-spinner />
      </div>
    </div>
    <div class="d-flex flex-column gap-3 mt-3">
      <button :disabled="!bundles" class='btn btn-outline-primary btn-block' @click="archiveCourse">
        Убрать в архив
      </button>
      <button class='btn btn-outline-secondary btn-block' @click="showArchiveModal = false">
        Отменить
      </button>
    </div>
  </b-modal>
</template>

<script setup>
import { ref, toRefs, computed } from 'vue';
import { useStore } from 'vuex';

import { makeToast } from '@/custom_functions/custom_functions';

const store = useStore();

const props = defineProps({ course: { type: Object, required: true } });
const { course } = toRefs(props);

const showArchiveModal = ref(false);

async function archiveCourse() {
  await store.dispatch('courses/archiveCourse', course.value);
  showArchiveModal.value = false;
  makeToast(`Курс «${course.value.name}» отправлен в архив.`, 'success');
}

async function removeCourseFromArchive() {
  await store.dispatch('courses/removeCourseFromArchive', course.value);
  makeToast(`Курс «${course.value.name}» переведён в статус Черновик.`, 'success');
}

function openArchiveModal() {
  store.dispatch('course/fetchCourse', course.value.id);
  showArchiveModal.value = true;
}

const bundles = computed(() => {
  const course = store.getters['course/getCourse'];
  return course?.bundles;
});

function toggleArchived() {
  if (course.value.publication_status === 'archived') removeCourseFromArchive();
  else openArchiveModal();
}

const icon = computed(() => {
  return course.value.publication_status === 'archived' ? 'box-open' : 'box-archive';
});

const buttonText = computed(() => {
  return course.value.publication_status === 'archived' ? 'Из архива' : 'В архив';
});
</script>

