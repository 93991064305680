<template>
  <div class="mb-4">
    <b-button
      variant="link"
      class="text-dark no-outline p-0 mb-3"
      aria-controls="collapse-params"
      @click="visible = !visible"
    >
      <span class="h3 me-2">Параметры</span>
      <font-awesome-icon
        class="arrow"
        :class="visible ? null : 'collapsed'"
        icon="angle-down"
        size="lg"
      />
    </b-button>

    <b-collapse
      id="collapse-params"
      v-model="visible"
      class="mt-2"
    >
      <div class="row">
        <div class="col-4">
          <div class="mb-3">
            <label class="mb-2">Название кампании*</label>
            <input
              v-model="campaign.name"
              type="text"
              class="form-control"
            />
          </div>

          <div class="mb-3">
            <label class="mb-2">Дата начала</label>
            <b-form-datepicker
              v-model="campaign.start_date"
              placeholder=''
              locale='ru'
              class="form-control"
              :min="minStartDate"
              :max="maxStartDate"
            />
          </div>

          <div class="mb-3">
            <label class="mb-2">Количество использований</label>
            <input
              v-model="campaign.global_limit"
              type="number"
              class="form-control"
            />
          </div>

          <div class="mb-3">
            <label class="mb-2">Количество курсов в заказе для активации промокода(>=)</label>
            <input
              v-model="campaign.courses_count_for_activation"
              type="number"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-4">
          <div class="row">
            <div class="mb-3 col-7">
              <label class="mb-2">Единицы измерения*</label>
              <select
                id="unit"
                v-model="campaign.unit"
                name="unit"
                class="form-control"
              >
                <option value="percents">Проценты</option>
                <option value="rubles">Рубли</option>
              </select>
            </div>
            <div class="mb-3 col-5">
              <label class="mb-2">Скидка, {{campaign.unit === 'rubles' ? '₽' : '%'}}*</label>
              <b-form-input
                v-model="campaign.discount"
                type="number"
                min="0"
                :max="campaign.unit === 'percents' && 100"
              />
            </div>
          </div>

          <div class="mb-3">
            <label class="mb-2">Дата окончания</label>
            <b-form-datepicker
              v-model="campaign.end_date"
              placeholder=''
              locale='ru'
              class="campaign.end_date"
              :min="minEndDate"
            />
          </div>

          <div class="mb-3">
            <label class="mb-2">Количество использований для аккаунта</label>
            <input
              v-model="campaign.per_account_limit"
              type="number"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-4">
          <div class="mb-3 form-check">
            <input
              id="onlyNewUsers"
              v-model="campaign.only_new_users"
              type="checkbox"
              class="form-check-input"
            />
            <label
              class="form-check-label"
              for="onlyNewUsers"
            >Только для новых пользователей</label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'CampaignParams',

  props: {
    campaign: Object,
  },

  data() {
    return {
      visible: true,
    };
  },

  computed: {
    minStartDate() {
      return new Date();
    },

    maxStartDate() {
      if (this.campaign && this.campaign.end_date) {
        return new Date(this.campaign.end_date);
      }
    },

    minEndDate() {
      if (this.campaign && this.campaign.start_date) {
        return new Date(this.campaign.start_date);
      }
      return new Date();
    },
  },
};
</script>

<style scoped>
.no-outline:focus {
  box-shadow: none;
}

.arrow {
  transition: all 0.3s;
}

.collapsed {
  transform: rotate(180deg);
}
</style>
