import { apiClient } from '../../custom_functions/api_client';

export default {
  namespaced: true,

  state: {
    totalPages: null,
    coursesReviews: null,
  },

  getters: {
    getCoursesReviewsTotalPages(state) {
      return state.totalPages;
    },
    getCoursesReviews(state) {
      return state.coursesReviews;
    },
  },

  actions: {
    async fetchCoursesReviews({ commit }, payload) {
      const response = await apiClient.get(
        '/v2/reviews',
        { params: payload },
      );

      commit('updateCoursesReviewsTotalPages', response.data.meta.total_pages);
      commit('updateCoursesReviews', response.data.reviews);
    },
  },

  mutations: {
    updateCoursesReviewsTotalPages(state, totalPages) {
      state.totalPages = totalPages;
    },
    updateCoursesReviews(state, reviews) {
      state.coursesReviews = reviews;
    },
  },
};