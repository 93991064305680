import validateNotEmptyField from '../validate_not_empty_field';
import validateTildaUrl from '../validate_tilda_url';

export default function validateProductExternalUrl(
  value,
  { publicationStatus = 'published' } = { publicationStatus: 'published' },
) {
  let errors = [];
  if (publicationStatus === 'published') errors = errors.concat(validateNotEmptyField(value));
  if (value) errors = errors.concat(validateTildaUrl(value));
  return errors;
}
