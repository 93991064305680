import { apiClient } from '@/custom_functions/api_client';
import { validateContainer } from '@/custom_functions/validation_functions';

export default {
  namespaced: true,

  state: {
    container: null,
    errors: {},
    conflict: null,
    httpProcess: false,
  },

  getters: {
    getContainer: (state) => state.container,
    getErrors: (state) => state.errors,
    getHasErrors: (state) => !!Object.keys(state.errors).length,
    getConflict: (state) => state.conflict,
    getHttpProcess: (state) => state.httpProcess,
  },

  actions: {
    createContainer({ commit }) {
      commit('updateContainer', {
        linked_courses_ids: [],
        experience_level: null,
        direction_ids: [],
        expire_days: 730,
        tags: [],
        publication_status: 'draft',
        preorder: false,
      });
    },

    async fetchContainer({ commit }, containerId) {
      commit('updateContainer', null);
      const response = await apiClient.get(`/v2/courses/${containerId}`);
      commit('updateContainer', response.data.course);
    },

    async saveContainer({ commit, state, dispatch, getters }) {
      dispatch('validateContainer');
      if (!getters.getHasErrors) {
        commit('updateHttpProcess', true);
        try {
          const response = await apiClient.post('/v2/courses', { course: state.container });
          commit('updateContainer', response.data.course);
        }
        finally {
          commit('updateHttpProcess', false);
        }
      }
    },

    async editContainer({ commit, state, dispatch, getters }) {
      dispatch('validateContainer');
      if (!getters.getHasErrors) {
        try {
          commit('updateHttpProcess', true);
          const response = await apiClient.patch(`/v2/courses/${state.container.id}`, {
            course: state.container,
          });
          commit('updateContainer', { ...state.course, ...response.data.course });
        } catch (e) {
          if (e.response.status === 409) commit('updateConflict', e.response.data);
          throw e;
        } finally {
          commit('updateHttpProcess', false);
        }
      }
    },

    validateContainer({ commit, state }) {
      commit('updateErrors', validateContainer(state.container));
    },
  },

  mutations: {
    updateContainer(state, value) {
      state.container = value;
    },

    updateErrors(state, value) {
      state.errors = value;
    },

    updateConflict(state, value) {
      state.conflict = value;
    },

    updateHttpProcess(state, value) {
      state.httpProcess = value;
    },
  },
};
