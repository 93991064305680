<template>
  <div class="btn-group">
    <button class="btn" :class="filter === 'all' ? 'btn-primary' : 'btn-outline-primary'" @click="filter = 'all'">Все</button>
    <button class="btn" :class="filter === 'active' ? 'btn-primary' : 'btn-outline-primary'" @click="filter = 'active'">
      Активные ({{ activeSpeakers.length }})
    </button>
    <button class="btn" :class="filter === 'archived' ? 'btn-primary' : 'btn-outline-primary'" @click="filter = 'archived'">
      В архиве ({{ archivedSpeakers.length }})
    </button>
  </div>
</template>

<script>
export default {
  name: 'SpeakersArchiveFilter',

  props: {
    modelValue: null,
  },

  data() {
    return {
      filter: 'all',
    };
  },

  computed: {
    activeSpeakers() {
      return this.speakers.filter((speaker) => speaker.archived === false);
    },
    archivedSpeakers() {
      return this.speakers.filter((speaker) => speaker.archived === true);
    },

    speakers() {
      return this.$store.getters['speakers/getSpeakers'];
    },
  },

  watch: {
    filter(value) {
      if (value === 'all') this.$emit('update:modelValue', this.speakers);
      if (value === 'active') this.$emit('update:modelValue', this.activeSpeakers); 
      if (value === 'archived') this.$emit('update:modelValue', this.archivedSpeakers); 
    },
  },

  created() {
    this.$emit('update:modelValue', this.speakers);
  },
};
</script>
