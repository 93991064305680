<template>
  <div class="container pb-5">
    <div v-if="course && lesson && unit">
      <div class="d-flex no-block align-items-center justify-content-between mb-2 row">
        <div class="h5 text-primary col-10 d-flex">
          <router-link :id="`course-link-${course.url}`" :to="courseLink" class="hidden-text">{{ course.name }}</router-link>
          <b-tooltip :target="`course-link-${course.url}`" triggers="hover">{{ course.name }}</b-tooltip>
          <span class="mx-2">/</span>
          <router-link :id="`unit-link-${unit.id}`" :to="unitLink" class="hidden-text">{{ unit.name }}</router-link>
          <b-tooltip :target="`unit-link-${unit.id}`" triggers="hover">{{ unit.name }}</b-tooltip>
          <span class="mx-2">/</span>
          <span class="lesson-title">{{ lesson.title }}</span>
          <a :id="`step-link-${this.$route.params.stepId}`" :href="linkToLK" target="_blank"
             class="mx-2">
            <font-awesome-icon icon="up-right-from-square" />
          </a>
          <b-tooltip :target="`step-link-${this.$route.params.stepId}`" triggers="hover">Перейти к шагу в личном кабинете</b-tooltip>
        </div>
        <LessonSettings class="col-2" />
      </div>
      
      <LessonSteps />
      
    </div>

    <div v-else class="loader text-center">
      <b-spinner/>
    </div>
  </div>
</template>

<script>
import LessonSettings from '@/components/lessons/LessonSettings.vue';
import LessonSteps from '@/components/lessons/LessonSteps.vue';

export default {
  name: 'LessonsEdit',

  components: { LessonSettings, LessonSteps },

  computed: {
    unit() {
      return this.$store.getters['course/getUnit'](this.$route.query.unit);
    },

    courseLink() {
      return this.$store.getters['course/getCourseLink'];
    },

    unitLink() {
      return this.$store.getters['course/getUnitLink'](this.$route.query.unit);
    },

    lesson() {
      return this.$store.getters['lesson/getLesson'];
    },

    course() {
      return this.$store.getters['course/getCourse'];
    },

    linkToLK() {
      const { course, unit } = this.$route.query;
      const { id, stepId } = this.$route.params;
      const adminHost = document.location.host;
      const protocol = document.location.protocol;
      let lkHost;
      if (adminHost === 'lvh.me:8081' || adminHost === 'localhost') lkHost = 'lvh.me:8080';
      else if (adminHost === 'admin2.dev.slurm.io') lkHost = 'frontend.dev.slurm.io';
      else lkHost = 'edu.slurm.io';
      return `${protocol}//${lkHost}/courses/${course}/units/${unit}/lessons/${id}/steps/${stepId}`;
    },
  },

  created() {
    this.init();
  },

  mounted() {
    this.$root.$on('bv::tooltip::show', (bvEvent) => {
      const elName = bvEvent.target;
      if (elName && !elName.scrollWidth > elName.clientWidth && elName.classList.contains('hidden-text')) bvEvent.preventDefault();
    });
  },

  methods: {
    async init() {
      this.$store.dispatch('lesson/fetchLesson', { courseUrl: this.$route.query.course, unitId: this.$route.query.unit, id: this.$route.params.id });
      this.$store.dispatch('course/fetchCourse', this.$route.query.course);
    },
  },
};
</script>

<style scoped>
.hidden-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.lesson-title {
  white-space: nowrap;
}
</style>
