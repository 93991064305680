<template>
  <div class="mb-3">
    <label for="script-check" class="mb-2">Скрипт проверки:</label>
    <input id="script-check" v-model="step.data.script_check" class="form-control" />
    <small class="form-text text-muted mb-3">Задание студента будет проверять соответствующий скрипт проверки.</small>
    <div class="form-check mt-3">
      <input id="show-textarea" v-model.lazy="step.data.settings.show_textarea" class="form-check-input"
             type="checkbox" />
      <label class="form-check-label" for="show-textarea">Показывать окно ввода текста (текст доступен через перенную <b>$textarea_input</b>)</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InteractStep',

  props: { step: { type: Object, required: true } },
};
</script>
