<template>
  <div class="d-flex align-items-center">
    {{ title }}
    <span v-if="hasErrors" class="d-block bg-danger rounded-circle ms-2" style="width: 6px; height: 6px;"/>
  </div>
</template>

<script setup>
// TODO: reuse it in courses/Edit and courses/New
import { computed, toRefs } from 'vue';

const props = defineProps({
  keys: {
    type: Array,
    required: true,
    validator: (keys) => keys.every((key) => typeof key === 'string'),
  },
  errors: {
    type: Object,
    required: true,
    validator: (errors) => {
      return Object.values(errors).every((fieldErrors) => {
        return (
          Array.isArray(fieldErrors)
          && fieldErrors.every((error) => typeof error === 'string')
        );
      });
    },
  },
  title: { type: String, required: true },
});

const { keys, errors, title } = toRefs(props);

const hasErrors = computed(() => Object.keys(errors.value).find((key) => keys.value.includes(key)));
</script>
