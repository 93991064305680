<template>
  <b-tabs v-model="tabValue">
    <b-tab v-for="tab in tabs" :key="tab.value">
      <template #title>
        <TabTitle :title="tab.title" :errors="errors" :keys="tab.fieldKeys"/>
      </template>
      <component :is="tab.component" class="mt-3" course-item-type="course"/>
    </b-tab>
  </b-tabs>
</template>

<script setup>
import TabTitle from '@/components/TabTitle';
import CourseMain from '@/components/courses/CourseMain';
import CoursePrice from '@/components/courses/CoursePrice';
import CourseCalendarAndSettings from '@/components/courses/CourseCalendarAndSettings';
import CourseContent from '@/components/courses/CourseContent';
import CourseBlog from '@/components/courses/CourseBlog';
import CourseParticipations from '@/components/courses/CourseParticipations';
import CourseReviews from '@/components/courses/CourseReviews';
import ExternalPromoCodes from '@/components/courses/ExternalPromoCodes';
import GitlabResources from '@/components/courses/gitlab_resources/index';

import { computed, watch, ref } from 'vue';
import { useStore } from 'vuex';

import { useRouteTabs } from '@/composables/use_route_tabs';

const store = useStore();

const errors = computed(() => store.getters['course/getErrors']);
const course = computed(() => store.getters['course/getCourse']);

const props = defineProps({ isNew: { type: Boolean, default: false } });

let tabs = ref([
  {
    value: 'main',
    fieldKeys: [
      'displayed_type',
      'product_ids',
      'stream_name',
      'tariff_name',
      'url',
      'po_user_id',
      'expire_days',
      'zoom_url',
      'speaker_ids',
      'memo',
    ],
    title: 'Основное',
    component: CourseMain,
    isNew: true,
  },
  {
    value: 'price',
    fieldKeys: ['preorder', 'prerelease_text', 'price', 'can_use_installment'],
    title: 'Цена',
    component: CoursePrice,
    isNew: true,
  },
  {
    value: 'calendar_and_settings',
    fieldKeys: [
      'date_from',
      'date_to',
      'show_in_calendar',
      'tags',
      'script_start',
      'script_stop',
      'engineering_chat_available',
      'with_certificate',
    ],
    title: (
      (course.value.course_type === 'slurm' || course.value.displayed_type === 'online_course')
        ? 'Календарь и настройки'
        : 'Настройки'
    ),
    component: CourseCalendarAndSettings,
    isNew: true,
  },
  {
    value: 'content',
    fieldKeys: [],
    title: 'Содержание',
    component: CourseContent,
    isNew: false,
  },
  {
    value: 'blog',
    fieldKeys: [],
    title: 'Дневник курса',
    component: CourseBlog,
    isNew: false,
  },
  {
    value: 'students',
    fieldKeys: [],
    title: 'Студенты',
    component: CourseParticipations,
    isNew: false,
  },
  {
    value: 'feedback',
    fieldKeys: [],
    title: 'Обратная связь',
    component: CourseReviews,
    isNew: false,
  },
  {
    value: 'promocodes',
    fieldKeys: [],
    title: 'Промокоды',
    component: ExternalPromoCodes,
    isNew: false,
  },
  {
    value: 'gitlab',
    fieldKeys: [],
    title: 'Gitlab',
    component: GitlabResources,
    isNew: false,
  },
]);

watch(() => course.value.course_type, () => {
  tabs.value[2].title = (
    (course.value.course_type === 'slurm' || course.value.displayed_type === 'online_course')
      ? 'Календарь и настройки'
      : 'Настройки'
  );
});

watch(() => course.value.displayed_type, () => {
  tabs.value[2].title = (
    (course.value.course_type === 'slurm' || course.value.displayed_type === 'online_course')
      ? 'Календарь и настройки'
      : 'Настройки'
  );
});

if (props.isNew) tabs.value = tabs.value.filter((tab) => tab.isNew);

const { tabValue } = useRouteTabs(tabs.value, 'main');
</script>
