<template>
  <div class="row">
    <div class="mb-4 col-3">
      <label for="course-date-from" class="mb-2">Дата начала*</label>
      <b-datepicker
        id="course-date-from"
        v-model="dateFromDate"
        :class="errors.date_from && 'is-invalid'"
        locale='ru'
        start-weekday="1"
        placeholder=""
        :date-format-options="dateFormatOptions"
      />
      <span v-for="error in errors.date_from" class="invalid-feedback">{{ error }}</span>
    </div>
    <div class="mb-4 col-3">
      <label for="course-date-to" class="mb-2">Дата окончания*</label>
      <b-datepicker
        id="course-date-to"
        v-model="dateToDate"
        :class="errors.date_to && 'is-invalid'"
        locale='ru'
        start-weekday="1"
        placeholder=""
        :date-format-options="dateFormatOptions"
      />
      <span v-for="error in errors.date_to" class="invalid-feedback">{{ error }}</span>
    </div>
  </div>
  <div class="form-check form-switch mb-4">
    <input
      id="course-show-in-calendar"
      v-model="course.show_in_calendar"
      type="checkbox"
      class="form-check-input mb-3"
    />
    <label class="form-check-label" for="course-show-in-calendar">Показывать в календаре</label>
  </div>
  <div class="mb-4 col-3">
    <label class="mb-2" for="course-date-from-time">
      Время начала, МСК{{ course.show_in_calendar ? '*' : '' }}
    </label>
    <input
      id="course-date-from-time"
      v-model.lazy="dateFromTime"
      class="form-control"
      type="time"
      :disabled="!course.show_in_calendar"
    />
  </div>
</template>

<script setup>
import moment from 'moment';

import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const course = computed(() => store.getters['course/getCourse']);

const errors = computed(() => store.getters['course/getErrors']);

const dateFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };

function getDate(value) {
  return value ? moment(value).utcOffset('GMT+03:00').format('YYYY-MM-DD') : null;
}

function setDate(value, field) {
  if (value) {
    const time = course.value[field]
      ? moment(course.value[field]).utcOffset('GMT+03:00').format('HH:mm:ss')
      : '10:00:00';
    const date = `${value}T${time}+03:00`;
    course.value[field] = date;
  } else course.value[field] = null;
}

const dateFromDate = computed({
  get: () => getDate(course.value.date_from),
  set: (value) => setDate(value, 'date_from'),
});

const dateToDate = computed({
  get: () => getDate(course.value.date_to),
  set: (value) => setDate(value, 'date_to'),
});

const dateFromTime = computed({
  get: () => course.value.date_from
    ? moment(course.value.date_from).utcOffset('GMT+03:00').format('HH:mm:ss')
    : null,
  set: (value) => {
    if (value) {
      const mDate = moment(course.value.date_from || new Date())
        .utcOffset('GMT+03:00')
        .format('YYYY-MM-DD');
      const date = `${mDate}T${value}+03:00`;
      course.value.date_from = date;
    }
  },
});

</script>
