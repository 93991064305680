<template>
  <div
    v-if="company"
    class="container"
  >
    <div class="mb-4">
      <div class="d-flex no-block align-items-center justify-content-between">
        <h2 class="mb-4">Компания {{ company.name }}</h2>

        <router-link
          class="btn btn-primary"
          :to="{ name: 'companies_edit', params: { id: company.id} }"
        >
          Редактировать
        </router-link>
      </div>
    </div>

    <div
      class="table-responsive"
    >
      <table class="table table-hover table-responsive-lg">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Имя</th>
            <th scope="col">Логин</th>
            <th scope="col">Почта</th>
            <th scope="col">Телефон</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(student, index) in company.students"
            :key="index"
          >
            <th scope="row">{{ student.id }}</th>
            <td>{{ student.full_name }}</td>
            <td>{{ student.login }}</td>
            <td>{{ student.email }}</td>
            <td>{{ student.phone }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div
    v-else
    class="loader text-center"
  >
    <b-spinner/>
  </div>
</template>

<script>
export default {
  name: 'companies_show', // eslint-disable-line vue/component-definition-name-casing

  data() {
    return {
      company: null,
    };
  },

  created() {
    this.getCompany();
  },

  methods: {
    async getCompany() {
      const response = await this.$apiClient.get(
        `/v2/companies/${this.$route.params.id}`,
      );

      this.company = response.data.company;
    },
  },
};
</script>
